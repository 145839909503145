/*------ Accordion -------*/

.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
ul li ul:hover {
	visibility: visible;
	opacity: 1;
	display: block;
}
.example .pagination {
	margin-bottom: 0;
}
.pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
}
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	background-color: $white;
	border: 1px solid #eff0f6;
	color: $color;
	box-shadow: none;
	&:hover {
		z-index: 2;
		color: #295a9f;
		text-decoration: none;
		background-color: rgba(70, 127, 207, 0.06);
		box-shadow: none;
		outline: 0;
	}
	&:focus {
		z-index: 2;
		outline: 0;
		box-shadow: none;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}
.page-item {
	&:first-child .page-link {
		margin-left: 0;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	&:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	&.active .page-link {
		z-index: 1;
		color: $white;
		background-color: $primary;
		border-color: $primary;
		&:hover {
			color: $white !important;
		}
	}
	&.disabled .page-link {
		color: #ced4da;
		pointer-events: none;
		cursor: auto;
		background-color: $white;
		border-color: #eff0f6;
	}
}
.pagination-lg {
	.page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.125rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}
.pagination-sm {
	.page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}
/*-------Accordion-----------*/

.panel-group .panel {
	border-radius: 4px;
	box-shadow: none;
	border: 1px solid #eff0f6;
}
.panel-default>.panel-heading {
	padding: 0;
	border-radius: 0;
	color: $primary;
	background-color: #f4f5fb;
}
.panel-title {
	font-size: 14px;
	margin-bottom: 0;
	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}
}
.more-less {
	float: right;
	color: #212121;
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 1px solid #eff0f6;
	line-height: 25px;
	font-size: 14px;
}
.panel>.list-group {
	margin-bottom: 0;
	.list-group-item:first-child {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
}
.panel-heading+.list-group .list-group-item:first-child {
	border-top-width: 0;
}
.panel-default .list-group-item.active {
	color: $black;
	background-color: #f4f5fb;
	border-color: #f4f5fb;
	&:hover, &:focus {
		color: $black;
		background-color: #f4f5fb;
		border-color: #f4f5fb;
	}
}
.panel-primary .list-group-item.active {
	color: #FFF;
	background-color: $primary;
	border-color: $primary;
	&:hover {
		color: #FFF !important;
		background-color: #255be6;
		border-color: #255be6;
	}
}
.panel-success .list-group-item.active {
	color: $white;
	background-color: $success !important;
	border-color: $success !important;
	&:hover {
		color: $white !important;
		background-color: #15ca7c;
		border-color: #15ca7c;
	}
}
.panel-info .list-group-item.active {
	color: $white;
	background-color: $info !important;
	border-color: $info;
	&:hover {
		color: $white !important;
		background-color: #2d9eef;
		border-color: #2d9eef;
	}
}
.panel-warning .list-group-item.active {
	color: $white;
	background-color: $warning !important;
	border-color: $warning;
	&:hover {
		color: $white !important;
		background-color: #cea70c;
		border-color: #cea70c;
	}
}
.panel-danger .list-group-item.active {
	color: $white;
	background-color: #dc0441 !important;
	border-color: #dc0441;
	&:hover {
		color: $white !important;
		background-color: #a15226;
		border-color: #a15226;
	}
}
.wrap {
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px $black-2, 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}
a {
	&:focus, &:hover, &:active {
		outline: 0;
		text-decoration: none;
	}
}
.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}
.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}
.panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
}
.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}
.panel-group1 .panel-body {
	border: 1px solid #eff0f6;
}
.panel-title1 a {
	display: block;
	color: $white;
	padding: 15px 20px;
	position: relative;
	font-size: 16px;
	background: $primary;
	font-weight: 400;
}
#accordion1 .panel-title1 a:hover {
	color: $white !important;
}
.panel-body1 {
	background: $white;
	padding: 10px;
}
.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius 0.3s linear 0.2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}
/* #bs-collapse icon scale option */

.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
}
.panel-heading1 {
	color: $white;
	padding: 0;
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 13px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 13px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all 0.5s;
		transform: scale(0);
	}
}
.demo-accordion p:last-child, .panel-group1 p:last-child {
	margin-bottom: 0;
}
#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all 0.5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all 0.5s;
	}
}
/* #accordion rotate icon option */

#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all 0.5s;
	}
	&.active a:before {
		transform: rotate(0deg);
		transition: all 0.5s;
	}
}