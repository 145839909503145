/*------ Alignments -------*/

.align-baseline {
	vertical-align: baseline !important;
}
.align-top {
	vertical-align: top !important;
}
.align-middle {
	vertical-align: middle !important;
}
.align-bottom {
	vertical-align: bottom !important;
}
.align-text-bottom {
	vertical-align: text-bottom !important;
}
.align-text-top {
	vertical-align: text-top !important;
}
.bg-primary {
	background-color: $primary !important;
}
a.bg-primary {
	&:hover, &:focus {
		background-color: #24987E !important;
	}
}
button.bg-primary {
	&:hover, &:focus {
		background-color: #24987E !important;
	}
}
.bg-secondary {
	background-color: $secondary !important;
}
a.bg-secondary {
	&:hover, &:focus {
		background-color: $secondary !important;
	}
}
button.bg-secondary {
	&:hover, &:focus {
		background-color: $secondary !important;
	}
}
.bg-success {
	background-color: $success !important;
}
a.bg-success {
	&:hover, &:focus {
		background-color: #15ca7c !important;
	}
}
button.bg-success {
	&:hover, &:focus {
		background-color: #15ca7c !important;
	}
}
.bg-info {
	background-color: $info !important;
}
a.bg-info {
	&:hover, &:focus {
		background-color: #1594ef !important;
	}
}
button.bg-info {
	&:hover, &:focus {
		background-color: #1594ef !important;
	}
}
.bg-warning {
	background-color: $warning !important;
}
a.bg-warning {
	&:hover, &:focus {
		background-color: #c29d0b !important;
	}
}
button.bg-warning {
	&:hover, &:focus {
		background-color: #c29d0b !important;
	}
}
.bg-danger {
	background-color: $danger !important;
}
a.bg-danger {
	&:hover, &:focus {
		background-color: #a11918 !important;
	}
}
button.bg-danger {
	&:hover, &:focus {
		background-color: #a11918 !important;
	}
}
.bg-light {
	background-color: #f4f5fb !important;
}
a.bg-light {
	&:hover, &:focus {
		background-color: #dae0e5 !important;
	}
}
button.bg-light {
	&:hover, &:focus {
		background-color: #dae0e5 !important;
	}
}
.bg-light2 {
	background-color: #fbfbfb !important;
}
a.bg-light2 {
	&:hover, &:focus {
		background-color: #f4f5fb !important;
	}
}
button.bg-light2 {
	&:hover, &:focus {
		background-color: #f4f5fb !important;
	}
}
.bg-light3 {
	background-color: #d3dfea !important;
}
.bg-light4 {
	background-color: #f7f7f7 !important;
}
.bg-dark {
	background-color: $dark !important;
}
a.bg-dark {
	&:hover, &:focus {
		background-color: #1d2124 !important;
	}
}
button.bg-dark {
	&:hover, &:focus {
		background-color: #1d2124 !important;
	}
}
.bg-white {
	background-color: $white !important;
}
.bg-transparent {
	background-color: transparent !important;
}
.bg-gradient-primary {
	background: -webkit-gradient(linear, left top, right top, from($primary), to(#113ca9));
}
.bg-gradient-success {
	background-image: linear-gradient(to right, #63d457 0, #9fe797 100%);
}
.bg-gradient-info {
	background-image: linear-gradient(to right, #1e9fc4 0, #69c7de 100%);
}
.bg-gradient-warning {
	background-image: linear-gradient(to right, #fcaa01 0, #fece4d 100%);
}
.bg-gradient-teal {
	background-image: linear-gradient(to right, #00796b 0, #4db6ac 100%);
}
.bg-gradient-blue {
	background-image: linear-gradient(to right, #1976d2 0, $blue 100%);
}
.bg-gradient-danger {
	background-image: linear-gradient(to right, $danger 0, #fc9197 100%);
}
.bg-gray-100 {
	background-color: $gray-100;
}
.bg-gray-200 {
	background-color: $gray-200;
}
.bg-gray-300 {
	background-color: $gray-300;
}
.bg-gray-400 {
	background-color: $gray-400;
}
.bg-gray-500 {
	background-color: $gray-500;
}
.bg-gray-600 {
	background-color: $gray-600;
}
.bg-gray-700 {
	background-color: $gray-700;
}
.bg-gray-800 {
	background-color: $gray-800;
}
.bg-gray-900 {
	background-color: $color;
}
.bg-white-1 {
	background-color: $white-1;
}
.bg-white-2 {
	background-color: $white-2;
}
.bg-white-3 {
	background-color: $white-3;
}
.bg-white-4 {
	background-color: $white-4;
}
.bg-white-5 {
	background-color: $white-5;
}
.bg-white-6 {
	background-color: $white-6;
}
.bg-white-7 {
	background-color: $white-7;
}
.bg-white-8 {
	background-color: $white-8;
}
.bg-white-9 {
	background-color: $white-9;
}
.bg-black-1 {
	background-color: $black-1;
}
.bg-black-2 {
	background-color: $black-2;
}
.bg-black-3 {
	background-color: $black-3;
}
.bg-black-4 {
	background-color: $black-4;
}
.bg-black-5 {
	background-color: $black-5;
}
.bg-black-6 {
	background-color: $black-6;
}
.bg-black-7 {
	background-color: $black-7;
}
.bg-black-8 {
	background-color: $black-8;
}
.bg-black-9 {
	background-color: $black-9;
}
.border {
	border: $border!important;
}
.border-top {
	border-top: $border!important;
}
.border-end {
	border-right: $border!important;
}
.border-bottom {
	border-bottom: $border!important;
}
.border-start {
	border-left: $border!important;
}
.border-top-bottom {
	border-top: $border!important;
	border-bottom: $border!important;
}
.border-left-right {
	border-right: $border!important;
	border-left: $border!important;
}
.border-0 {
	border: 0 !important;
}
.border-top-0 {
	border-top: 0 !important;
}
.border-end-0 {
	border-right: 0 !important;
}
.border-bottom-0 {
	border-bottom: 0 !important;
}
.border-start-0 {
	border-left: 0 !important;
}
.border-top-bottom-0 {
	border-top: 0px solid #eff0f6 !important;
	border-bottom: 0px solid #eff0f6 !important;
}
.border-left-right-0 {
	border-right: 0px solid #eff0f6 !important;
	border-left: 0px solid #eff0f6 !important;
}
.border-primary {
	border-color: $primary !important;
}
.border-secondary {
	border-color: $secondary !important;
}
.border-success {
	border-color: $success !important;
}
.border-info {
	border-color: $info !important;
}
.border-warning {
	border-color: $warning !important;
}
.border-danger {
	border-color: #dc0441 !important;
}
.border-light {
	border-color: #f8f9fa !important;
}
.border-dark {
	border-color: $dark !important;
}
.border-transparent {
	border-color: rgba(255, 255, 255, 0.15) !important;
}
.border-dark-transparent {
	border-color: rgba(0, 0, 0, 0.15) !important;
}
.border-light2 {
	border-color: #d3dfea !important;
}
.border-white {
	border-color: $white !important;
}
.border-wd-1 {
	border-width: 1px !important;
}
.border-wd-2 {
	border-width: 2px !important;
}
.border-wd-3 {
	border-width: 3px !important;
}
.border-wd-4 {
	border-width: 4px !important;
}
.border-wd-5 {
	border-width: 5px !important;
}
.rounded {
	border-radius: 3px !important;
}
.rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}
.rounded-right {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}
.rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.rounded-left {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.rounded-0 {
	border-radius: 0 !important;
}
.clearfix {
	&::after {
		display: block;
		clear: both;
		content: "";
	}
	.fab {
		font-size: 25px;
	}
	h5 {
		font-size: 20px;
		text-weight: bold;
	}
}
i.fa.round {
	border: 1px solid $primary;
	border-radius: 50%;
	padding: 5px;
	width: 15%;
}
.list-icon span p {
	border: 1px solid $primary;
	border-radius: 15px;
	width: 50%;
	margin: 0px auto;
}
.d-none {
	display: none !important;
}
.d-inline {
	display: inline !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-block {
	display: block !important;
}
.d-table {
	display: table !important;
}
.d-table-row {
	display: table-row !important;
}
.d-table-cell {
	display: table-cell !important;
}
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}
@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media (min-width: 768px) {
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-md-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media (min-width: 1280px) {
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-print-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	&::before {
		display: block;
		content: "";
	}
	.embed-responsive-item, iframe, embed, object, video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
.embed-responsive-21by9::before {
	padding-top: 42.85714286%;
}
.embed-responsive-16by9::before {
	padding-top: 56.25%;
}
.embed-responsive-4by3::before {
	padding-top: 75%;
}
.embed-responsive-1by1::before {
	padding-top: 100%;
}
.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}
.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}
.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}
.flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}
.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}
.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}
.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}
.flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
}
.flex-grow-1 {
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important;
}
.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
}
.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
}
.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}
.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}
.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}
.align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}
.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}
.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}
.align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}
.align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}
.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}
.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}
.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}
.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}
.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}
.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}
.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
}
.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}
.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}
.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}
.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}
.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}
@media (min-width: 576px) {
	.flex-sm-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-sm-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-sm-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-sm-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-sm-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-sm-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-sm-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
@media (min-width: 768px) {
	.flex-md-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-md-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
@media (min-width: 992px) {
	.flex-lg-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-lg-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
@media (min-width: 1280px) {
	.flex-xl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-xl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
.float-start {
	float: left !important;
}
.float-end {
	float: right !important;
}
.float-none {
	float: none !important;
}
@media (min-width: 576px) {
	.float-sm-start {
		float: left !important;
	}
	.float-sm-end {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}
@media (min-width: 768px) {
	.float-md-start {
		float: left !important;
	}
	.float-md-end {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
}
@media (min-width: 992px) {
	.float-lg-start {
		float: left !important;
	}
	.float-lg-end {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}
@media (min-width: 1280px) {
	.float-xl-start {
		float: left !important;
	}
	.float-xl-end {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}
.position-static {
	position: static !important;
}
.position-relative {
	position: relative !important;
}
.position-absolute {
	position: absolute !important;
}
.position-fixed {
	position: fixed !important;
}
.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}
.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}
@supports (position: -webkit-sticky) or (position: sticky) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}
.sr-only-focusable {
	&:active, &:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}
.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
	box-shadow: none !important;
}
/*------ Widths, Margins & Paddings -------*/

.wd-5 {
	width: 5% !important;
}
.w-10 {
	width: 10% !important;
}
.w-15 {
	width: 15% !important;
}
.w-20 {
	width: 20% !important;
}
.w-25 {
	width: 25% !important;
}
.w-30 {
	width: 30% !important;
}
.w-35 {
	width: 35% !important;
}
.w-40 {
	width: 40% !important;
}
.w-45 {
	width: 45% !important;
}
.w-50 {
	width: 50% !important;
}
.w-55 {
	width: 55% !important;
}
.w-60 {
	width: 60% !important;
}
.w-65 {
	width: 65% !important;
}
.w-70 {
	width: 70% !important;
}
.w-75 {
	width: 75% !important;
}
.w-80 {
	width: 80% !important;
}
.w-85 {
	width: 85% !important;
}
.w-90 {
	width: 90% !important;
}
.w-95 {
	width: 95% !important;
}
.w-100 {
	width: 100% !important;
}
.w-150 {
	width: 150px !important;
}
.w-auto {
	width: auto !important;
}
.w-0 {
	width: 0 !important;
}
.w-1 {
	width: 0.25rem !important;
}
.w-2 {
	width: 0.5rem !important;
}
.w-3 {
	width: 0.75rem !important;
}
.w-4 {
	width: 1rem !important;
}
.w-5 {
	width: 1.5rem !important;
}
.w-6 {
	width: 2rem !important;
}
.w-7 {
	width: 3rem !important;
}
.w-8 {
	width: 4rem !important;
}
.w-9 {
	width: 6rem !important;
}
.w-100h {
	width: 100px !important;
}
.w-200 {
	width: 200px !important;
}
.w-250 {
	width: 250px !important;
}
.w-300 {
	width: 300px !important;
}
.w-350 {
	width: 350px !important;
}
.w-400 {
	width: 400px !important;
}
.w-450 {
	width: 450px !important;
}
.w-500 {
	width: 500px !important;
}
.ht-5 {
	height: 5% !important;
}
.h-10 {
	height: 10% !important;
}
.h-15 {
	height: 15% !important;
}
.h-20 {
	height: 20% !important;
}
.h-25 {
	height: 25% !important;
}
.h-30 {
	height: 30% !important;
}
.h-35 {
	height: 35% !important;
}
.h-40 {
	height: 40% !important;
}
.h-45 {
	height: 45% !important;
}
.h-50 {
	height: 50% !important;
}
.h-55 {
	height: 55% !important;
}
.h-60 {
	height: 60% !important;
}
.h-65 {
	height: 65% !important;
}
.h-70 {
	height: 70% !important;
}
.h-75 {
	height: 75% !important;
}
.h-80 {
	height: 80% !important;
}
.h-85 {
	height: 85% !important;
}
.h-90 {
	height: 90% !important;
}
.h-95 {
	height: 95% !important;
}
.h-100vh {
	height: 100vh !important;
}
.h-100 {
	height: 100% !important;
}
.h-auto {
	height: auto !important;
}
.h-0 {
	height: 0 !important;
}
.h-1 {
	height: 0.25rem !important;
}
.h-2 {
	height: 0.5rem !important;
}
.h-3 {
	height: 0.75rem !important;
}
.h-4 {
	height: 1rem !important;
}
.h-5 {
	height: 1.5rem !important;
}
.h-6 {
	height: 2rem !important;
}
.h-7 {
	height: 3rem !important;
}
.h-8 {
	height: 4rem !important;
}
.h-9 {
	height: 6rem !important;
}
.h-100h {
	height: 100px !important;
}
.h-150 {
	height: 150px !important;
}
.h-200 {
	height: 200px !important;
}
.h-250 {
	height: 250px !important;
}
.h-300 {
	height: 300px !important;
}
.h-350 {
	height: 350px !important;
}
.h-370 {
	height: 370px !important;
}
.h-400 {
	height: 400px !important;
}
.h-420 {
	height: 420px !important;
}
.h-450 {
	height: 450px !important;
}
.h-500 {
	height: 500px !important;
}
.mw-100 {
	max-width: 100% !important;
}
.mh-100 {
	max-height: 100% !important;
}
.m-0 {
	margin: 0 !important;
}
.mt-0, .my-0 {
	margin-top: 0 !important;
}
.me-0, .mx-0 {
	margin-right: 0 !important;
}
.mb-0, .my-0 {
	margin-bottom: 0 !important;
}
.ms-0, .mx-0 {
	margin-left: 0 !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.mt-1, .my-1 {
	margin-top: 0.25rem !important;
}
.me-1, .mx-1 {
	margin-right: 0.25rem !important;
}
.mb-1, .my-1 {
	margin-bottom: 0.25rem !important;
}
.ms-1, .mx-1 {
	margin-left: 0.25rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.mt-2, .my-2 {
	margin-top: 0.5rem !important;
}
.me-2, .mx-2 {
	margin-right: 0.5rem !important;
}
.mb-2, .my-2 {
	margin-bottom: 0.5rem !important;
}
.ms-2, .mx-2 {
	margin-left: 0.5rem !important;
}
.m-3 {
	margin: 0.75rem !important;
}
.mt-3, .my-3 {
	margin-top: 0.75rem !important;
}
.me-3, .mx-3 {
	margin-right: 0.75rem !important;
}
.mb-3, .my-3 {
	margin-bottom: 0.75rem !important;
}
.ms-3, .mx-3 {
	margin-left: 0.75rem !important;
}
.m-4 {
	margin: 1rem !important;
}
.mt-4, .my-4 {
	margin-top: 1rem !important;
}
.me-4, .mx-4 {
	margin-right: 1rem !important;
}
.mb-4, .my-4 {
	margin-bottom: 1rem !important;
}
.ms-4, .mx-4 {
	margin-left: 1rem !important;
}
.m-5 {
	margin: 1.5rem !important;
}
.mt-5, .my-5 {
	margin-top: 1.5rem !important;
}
.me-5, .mx-5 {
	margin-right: 1.5rem !important;
}
.mb-5, .my-5 {
	margin-bottom: 1.5rem !important;
}
.ms-5, .mx-5 {
	margin-left: 1.5rem !important;
}
.m-6 {
	margin: 2rem !important;
}
.mt-6, .my-6 {
	margin-top: 2rem !important;
}
.me-6, .mx-6 {
	margin-right: 2rem !important;
}
.mb-6, .my-6 {
	margin-bottom: 2rem !important;
}
.ms-6, .mx-6 {
	margin-left: 2rem !important;
}
.m-7 {
	margin: 3rem !important;
}
.mt-7, .my-7 {
	margin-top: 3rem !important;
}
.me-7, .mx-7 {
	margin-right: 3rem !important;
}
.mb-7, .my-7 {
	margin-bottom: 3rem !important;
}
.ms-7, .mx-7 {
	margin-left: 3rem !important;
}
.m-8 {
	margin: 4rem !important;
}
.mt-8, .my-8 {
	margin-top: 4rem !important;
}
.me-8, .mx-8 {
	margin-right: 4rem !important;
}
.mb-8, .my-8 {
	margin-bottom: 4rem !important;
}
.ms-8, .mx-8 {
	margin-left: 4rem !important;
}
.m-9 {
	margin: 6rem !important;
}
.mt-9, .my-9 {
	margin-top: 6rem !important;
}
.me-9, .mx-9 {
	margin-right: 6rem !important;
}
.mb-9, .my-9 {
	margin-bottom: 6rem !important;
}
.ms-9, .mx-9 {
	margin-left: 6rem !important;
}
.p-0 {
	padding: 0 !important;
}
.pt-0, .py-0 {
	padding-top: 0 !important;
}
.pe-0, .px-0 {
	padding-right: 0 !important;
}
.pb-0, .py-0 {
	padding-bottom: 0 !important;
}
.ps-0, .px-0 {
	padding-left: 0 !important;
}
.p-1 {
	padding: 0.25rem;
}
.pt-1, .py-1 {
	padding-top: 0.25rem !important;
}
.pe-1, .px-1 {
	padding-right: 0.25rem !important;
}
.pb-1, .py-1 {
	padding-bottom: 0.25rem !important;
}
.ps-1, .px-1 {
	padding-left: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-2, .py-2 {
	padding-top: 0.5rem !important;
}
.pe-2, .px-2 {
	padding-right: 0.5rem !important;
}
.pb-2, .py-2 {
	padding-bottom: 0.5rem !important;
}
.ps-2, .px-2 {
	padding-left: 0.5rem !important;
}
.p-3 {
	padding: 0.75rem !important;
}
.pt-3, .py-3 {
	padding-top: 0.75rem !important;
}
.pe-3, .px-3 {
	padding-right: 0.75rem !important;
}
.pb-3, .py-3 {
	padding-bottom: 0.75rem !important;
}
.ps-3, .px-3 {
	padding-left: 0.75rem !important;
}
.p-4 {
	padding: 1rem !important;
}
.pt-4, .py-4 {
	padding-top: 1rem !important;
}
.pe-4, .px-4 {
	padding-right: 1rem !important;
}
.pb-4, .py-4 {
	padding-bottom: 1rem !important;
}
.ps-4, .px-4 {
	padding-left: 1rem !important;
}
.p-5 {
	padding: 1.5rem !important;
}
.pt-5, .py-5 {
	padding-top: 1.5rem !important;
}
.pe-5, .px-5 {
	padding-right: 1.5rem !important;
}
.pb-5, .py-5 {
	padding-bottom: 1.5rem !important;
}
.ps-5, .px-5 {
	padding-left: 1.5rem !important;
}
.p-6 {
	padding: 2rem !important;
}
.pt-6, .py-6 {
	padding-top: 2rem !important;
}
.pe-6, .px-6 {
	padding-right: 2rem !important;
}
.pb-6, .py-6 {
	padding-bottom: 2rem !important;
}
.ps-6, .px-6 {
	padding-left: 2rem !important;
}
.p-7 {
	padding: 3rem !important;
}
.pt-7, .py-7 {
	padding-top: 3rem !important;
}
.pe-7, .px-7 {
	padding-right: 3rem !important;
}
.pb-7, .py-7 {
	padding-bottom: 3rem !important;
}
.ps-7, .px-7 {
	padding-left: 3rem !important;
}
.p-8 {
	padding: 4rem !important;
}
.pt-8, .py-8 {
	padding-top: 4rem !important;
}
.pe-8, .px-8 {
	padding-right: 4rem !important;
}
.pb-8, .py-8 {
	padding-bottom: 4rem !important;
}
.ps-8, .px-8 {
	padding-left: 4rem !important;
}
.p-9 {
	padding: 6rem !important;
}
.pt-9, .py-9 {
	padding-top: 6rem !important;
}
.pe-9, .px-9 {
	padding-right: 6rem !important;
}
.pb-9, .py-9 {
	padding-bottom: 6rem !important;
}
.ps-9, .px-9 {
	padding-left: 6rem !important;
}
.m-auto {
	margin: auto !important;
}
.mt-auto, .my-auto {
	margin-top: auto !important;
}
.me-auto, .mx-auto {
	margin-right: auto !important;
}
.mb-auto, .my-auto {
	margin-bottom: auto !important;
}
.ms-auto, .mx-auto {
	margin-left: auto !important;
}
@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0 !important;
	}
	.mt-sm-0, .my-sm-0 {
		margin-top: 0 !important;
	}
	.me-sm-0, .mx-sm-0 {
		margin-right: 0 !important;
	}
	.mb-sm-0, .my-sm-0 {
		margin-bottom: 0 !important;
	}
	.ms-sm-0, .mx-sm-0 {
		margin-left: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.mt-sm-1, .my-sm-1 {
		margin-top: 0.25rem !important;
	}
	.me-sm-1, .mx-sm-1 {
		margin-right: 0.25rem !important;
	}
	.mb-sm-1, .my-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.ms-sm-1, .mx-sm-1 {
		margin-left: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.mt-sm-2, .my-sm-2 {
		margin-top: 0.5rem !important;
	}
	.me-sm-2, .mx-sm-2 {
		margin-right: 0.5rem !important;
	}
	.mb-sm-2, .my-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.ms-sm-2, .mx-sm-2 {
		margin-left: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 0.75rem !important;
	}
	.mt-sm-3, .my-sm-3 {
		margin-top: 0.75rem !important;
	}
	.me-sm-3, .mx-sm-3 {
		margin-right: 0.75rem !important;
	}
	.mb-sm-3, .my-sm-3 {
		margin-bottom: 0.75rem !important;
	}
	.ms-sm-3, .mx-sm-3 {
		margin-left: 0.75rem !important;
	}
	.m-sm-4 {
		margin: 1rem !important;
	}
	.mt-sm-4, .my-sm-4 {
		margin-top: 1rem !important;
	}
	.me-sm-4, .mx-sm-4 {
		margin-right: 1rem !important;
	}
	.mb-sm-4, .my-sm-4 {
		margin-bottom: 1rem !important;
	}
	.ms-sm-4, .mx-sm-4 {
		margin-left: 1rem !important;
	}
	.m-sm-5 {
		margin: 1.5rem !important;
	}
	.mt-sm-5, .my-sm-5 {
		margin-top: 1.5rem !important;
	}
	.me-sm-5, .mx-sm-5 {
		margin-right: 1.5rem !important;
	}
	.mb-sm-5, .my-sm-5 {
		margin-bottom: 1.5rem !important;
	}
	.ms-sm-5, .mx-sm-5 {
		margin-left: 1.5rem !important;
	}
	.m-sm-6 {
		margin: 2rem !important;
	}
	.mt-sm-6, .my-sm-6 {
		margin-top: 2rem !important;
	}
	.me-sm-6, .mx-sm-6 {
		margin-right: 2rem !important;
	}
	.mb-sm-6, .my-sm-6 {
		margin-bottom: 2rem !important;
	}
	.ms-sm-6, .mx-sm-6 {
		margin-left: 2rem !important;
	}
	.m-sm-7 {
		margin: 3rem !important;
	}
	.mt-sm-7, .my-sm-7 {
		margin-top: 3rem !important;
	}
	.me-sm-7, .mx-sm-7 {
		margin-right: 3rem !important;
	}
	.mb-sm-7, .my-sm-7 {
		margin-bottom: 3rem !important;
	}
	.ms-sm-7, .mx-sm-7 {
		margin-left: 3rem !important;
	}
	.m-sm-8 {
		margin: 4rem !important;
	}
	.mt-sm-8, .my-sm-8 {
		margin-top: 4rem !important;
	}
	.me-sm-8, .mx-sm-8 {
		margin-right: 4rem !important;
	}
	.mb-sm-8, .my-sm-8 {
		margin-bottom: 4rem !important;
	}
	.ms-sm-8, .mx-sm-8 {
		margin-left: 4rem !important;
	}
	.m-sm-9 {
		margin: 6rem !important;
	}
	.mt-sm-9, .my-sm-9 {
		margin-top: 6rem !important;
	}
	.me-sm-9, .mx-sm-9 {
		margin-right: 6rem !important;
	}
	.mb-sm-9, .my-sm-9 {
		margin-bottom: 6rem !important;
	}
	.ms-sm-9, .mx-sm-9 {
		margin-left: 6rem !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.pt-sm-0, .py-sm-0 {
		padding-top: 0 !important;
	}
	.pe-sm-0, .px-sm-0 {
		padding-right: 0 !important;
	}
	.pb-sm-0, .py-sm-0 {
		padding-bottom: 0 !important;
	}
	.ps-sm-0, .px-sm-0 {
		padding-left: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.pt-sm-1, .py-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pe-sm-1, .px-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pb-sm-1, .py-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-sm-1, .px-sm-1 {
		padding-left: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.pt-sm-2, .py-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pe-sm-2, .px-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pb-sm-2, .py-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-sm-2, .px-sm-2 {
		padding-left: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 0.75rem !important;
	}
	.pt-sm-3, .py-sm-3 {
		padding-top: 0.75rem !important;
	}
	.pe-sm-3, .px-sm-3 {
		padding-right: 0.75rem !important;
	}
	.pb-sm-3, .py-sm-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-sm-3, .px-sm-3 {
		padding-left: 0.75rem !important;
	}
	.p-sm-4 {
		padding: 1rem !important;
	}
	.pt-sm-4, .py-sm-4 {
		padding-top: 1rem !important;
	}
	.pe-sm-4, .px-sm-4 {
		padding-right: 1rem !important;
	}
	.pb-sm-4, .py-sm-4 {
		padding-bottom: 1rem !important;
	}
	.ps-sm-4, .px-sm-4 {
		padding-left: 1rem !important;
	}
	.p-sm-5 {
		padding: 1.5rem !important;
	}
	.pt-sm-5, .py-sm-5 {
		padding-top: 1.5rem !important;
	}
	.pe-sm-5, .px-sm-5 {
		padding-right: 1.5rem !important;
	}
	.pb-sm-5, .py-sm-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-sm-5, .px-sm-5 {
		padding-left: 1.5rem !important;
	}
	.p-sm-6 {
		padding: 2rem !important;
	}
	.pt-sm-6, .py-sm-6 {
		padding-top: 2rem !important;
	}
	.pe-sm-6, .px-sm-6 {
		padding-right: 2rem !important;
	}
	.pb-sm-6, .py-sm-6 {
		padding-bottom: 2rem !important;
	}
	.ps-sm-6, .px-sm-6 {
		padding-left: 2rem !important;
	}
	.p-sm-7 {
		padding: 3rem !important;
	}
	.pt-sm-7, .py-sm-7 {
		padding-top: 3rem !important;
	}
	.pe-sm-7, .px-sm-7 {
		padding-right: 3rem !important;
	}
	.pb-sm-7, .py-sm-7 {
		padding-bottom: 3rem !important;
	}
	.ps-sm-7, .px-sm-7 {
		padding-left: 3rem !important;
	}
	.p-sm-8 {
		padding: 4rem !important;
	}
	.pt-sm-8, .py-sm-8 {
		padding-top: 4rem !important;
	}
	.pe-sm-8, .px-sm-8 {
		padding-right: 4rem !important;
	}
	.pb-sm-8, .py-sm-8 {
		padding-bottom: 4rem !important;
	}
	.ps-sm-8, .px-sm-8 {
		padding-left: 4rem !important;
	}
	.p-sm-9 {
		padding: 6rem !important;
	}
	.pt-sm-9, .py-sm-9 {
		padding-top: 6rem !important;
	}
	.pe-sm-9, .px-sm-9 {
		padding-right: 6rem !important;
	}
	.pb-sm-9, .py-sm-9 {
		padding-bottom: 6rem !important;
	}
	.ps-sm-9, .px-sm-9 {
		padding-left: 6rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mt-sm-auto, .my-sm-auto {
		margin-top: auto !important;
	}
	.me-sm-auto, .mx-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-auto, .my-sm-auto {
		margin-bottom: auto !important;
	}
	.ms-sm-auto, .mx-sm-auto {
		margin-left: auto !important;
	}
}
@media (min-width: 768px) {
	.m-md-0 {
		margin: 0 !important;
	}
	.mt-md-0, .my-md-0 {
		margin-top: 0 !important;
	}
	.me-md-0, .mx-md-0 {
		margin-right: 0 !important;
	}
	.mb-md-0, .my-md-0 {
		margin-bottom: 0 !important;
	}
	.ms-md-0, .mx-md-0 {
		margin-left: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.mt-md-1, .my-md-1 {
		margin-top: 0.25rem !important;
	}
	.me-md-1, .mx-md-1 {
		margin-right: 0.25rem !important;
	}
	.mb-md-1, .my-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.ms-md-1, .mx-md-1 {
		margin-left: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.mt-md-2, .my-md-2 {
		margin-top: 0.5rem !important;
	}
	.me-md-2, .mx-md-2 {
		margin-right: 0.5rem !important;
	}
	.mb-md-2, .my-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.ms-md-2, .mx-md-2 {
		margin-left: 0.5rem !important;
	}
	.m-md-3 {
		margin: 0.75rem !important;
	}
	.mt-md-3, .my-md-3 {
		margin-top: 0.75rem !important;
	}
	.me-md-3, .mx-md-3 {
		margin-right: 0.75rem !important;
	}
	.mb-md-3, .my-md-3 {
		margin-bottom: 0.75rem !important;
	}
	.ms-md-3, .mx-md-3 {
		margin-left: 0.75rem !important;
	}
	.m-md-4 {
		margin: 1rem !important;
	}
	.mt-md-4, .my-md-4 {
		margin-top: 1rem !important;
	}
	.me-md-4, .mx-md-4 {
		margin-right: 1rem !important;
	}
	.mb-md-4, .my-md-4 {
		margin-bottom: 1rem !important;
	}
	.ms-md-4, .mx-md-4 {
		margin-left: 1rem !important;
	}
	.m-md-5 {
		margin: 1.5rem !important;
	}
	.mt-md-5, .my-md-5 {
		margin-top: 1.5rem !important;
	}
	.me-md-5, .mx-md-5 {
		margin-right: 1.5rem !important;
	}
	.mb-md-5, .my-md-5 {
		margin-bottom: 1.5rem !important;
	}
	.ms-md-5, .mx-md-5 {
		margin-left: 1.5rem !important;
	}
	.m-md-6 {
		margin: 2rem !important;
	}
	.mt-md-6, .my-md-6 {
		margin-top: 2rem !important;
	}
	.me-md-6, .mx-md-6 {
		margin-right: 2rem !important;
	}
	.mb-md-6, .my-md-6 {
		margin-bottom: 2rem !important;
	}
	.ms-md-6, .mx-md-6 {
		margin-left: 2rem !important;
	}
	.m-md-7 {
		margin: 3rem !important;
	}
	.mt-md-7, .my-md-7 {
		margin-top: 3rem !important;
	}
	.me-md-7, .mx-md-7 {
		margin-right: 3rem !important;
	}
	.mb-md-7, .my-md-7 {
		margin-bottom: 3rem !important;
	}
	.ms-md-7, .mx-md-7 {
		margin-left: 3rem !important;
	}
	.m-md-8 {
		margin: 4rem !important;
	}
	.mt-md-8, .my-md-8 {
		margin-top: 4rem !important;
	}
	.me-md-8, .mx-md-8 {
		margin-right: 4rem !important;
	}
	.mb-md-8, .my-md-8 {
		margin-bottom: 4rem !important;
	}
	.ms-md-8, .mx-md-8 {
		margin-left: 4rem !important;
	}
	.m-md-9 {
		margin: 6rem !important;
	}
	.mt-md-9, .my-md-9 {
		margin-top: 6rem !important;
	}
	.me-md-9, .mx-md-9 {
		margin-right: 6rem !important;
	}
	.mb-md-9, .my-md-9 {
		margin-bottom: 6rem !important;
	}
	.ms-md-9, .mx-md-9 {
		margin-left: 6rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0, .py-md-0 {
		padding-top: 0 !important;
	}
	.pe-md-0, .px-md-0 {
		padding-right: 0 !important;
	}
	.pb-md-0, .py-md-0 {
		padding-bottom: 0 !important;
	}
	.ps-md-0, .px-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.pt-md-1, .py-md-1 {
		padding-top: 0.25rem !important;
	}
	.pe-md-1, .px-md-1 {
		padding-right: 0.25rem !important;
	}
	.pb-md-1, .py-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-md-1, .px-md-1 {
		padding-left: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.pt-md-2, .py-md-2 {
		padding-top: 0.5rem !important;
	}
	.pe-md-2, .px-md-2 {
		padding-right: 0.5rem !important;
	}
	.pb-md-2, .py-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-md-2, .px-md-2 {
		padding-left: 0.5rem !important;
	}
	.p-md-3 {
		padding: 0.75rem !important;
	}
	.pt-md-3, .py-md-3 {
		padding-top: 0.75rem !important;
	}
	.pe-md-3, .px-md-3 {
		padding-right: 0.75rem !important;
	}
	.pb-md-3, .py-md-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-md-3, .px-md-3 {
		padding-left: 0.75rem !important;
	}
	.p-md-4 {
		padding: 1rem !important;
	}
	.pt-md-4, .py-md-4 {
		padding-top: 1rem !important;
	}
	.pe-md-4, .px-md-4 {
		padding-right: 1rem !important;
	}
	.pb-md-4, .py-md-4 {
		padding-bottom: 1rem !important;
	}
	.ps-md-4, .px-md-4 {
		padding-left: 1rem !important;
	}
	.p-md-5 {
		padding: 1.5rem !important;
	}
	.pt-md-5, .py-md-5 {
		padding-top: 1.5rem !important;
	}
	.pe-md-5, .px-md-5 {
		padding-right: 1.5rem !important;
	}
	.pb-md-5, .py-md-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-md-5, .px-md-5 {
		padding-left: 1.5rem !important;
	}
	.p-md-6 {
		padding: 2rem !important;
	}
	.pt-md-6, .py-md-6 {
		padding-top: 2rem !important;
	}
	.pe-md-6, .px-md-6 {
		padding-right: 2rem !important;
	}
	.pb-md-6, .py-md-6 {
		padding-bottom: 2rem !important;
	}
	.ps-md-6, .px-md-6 {
		padding-left: 2rem !important;
	}
	.p-md-7 {
		padding: 3rem !important;
	}
	.pt-md-7, .py-md-7 {
		padding-top: 3rem !important;
	}
	.pe-md-7, .px-md-7 {
		padding-right: 3rem !important;
	}
	.pb-md-7, .py-md-7 {
		padding-bottom: 3rem !important;
	}
	.ps-md-7, .px-md-7 {
		padding-left: 3rem !important;
	}
	.p-md-8 {
		padding: 4rem !important;
	}
	.pt-md-8, .py-md-8 {
		padding-top: 4rem !important;
	}
	.pe-md-8, .px-md-8 {
		padding-right: 4rem !important;
	}
	.pb-md-8, .py-md-8 {
		padding-bottom: 4rem !important;
	}
	.ps-md-8, .px-md-8 {
		padding-left: 4rem !important;
	}
	.p-md-9 {
		padding: 6rem !important;
	}
	.pt-md-9, .py-md-9 {
		padding-top: 6rem !important;
	}
	.pe-md-9, .px-md-9 {
		padding-right: 6rem !important;
	}
	.pb-md-9, .py-md-9 {
		padding-bottom: 6rem !important;
	}
	.ps-md-9, .px-md-9 {
		padding-left: 6rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto, .my-md-auto {
		margin-top: auto !important;
	}
	.me-md-auto, .mx-md-auto {
		margin-right: auto !important;
	}
	.mb-md-auto, .my-md-auto {
		margin-bottom: auto !important;
	}
	.ms-md-auto, .mx-md-auto {
		margin-left: auto !important;
	}
}
@media (min-width: 992px) {
	.m-lg-0 {
		margin: 0 !important;
	}
	.mt-lg-0, .my-lg-0 {
		margin-top: 0 !important;
	}
	.me-lg-0, .mx-lg-0 {
		margin-right: 0 !important;
	}
	.mb-lg-0, .my-lg-0 {
		margin-bottom: 0 !important;
	}
	.ms-lg-0, .mx-lg-0 {
		margin-left: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.mt-lg-1, .my-lg-1 {
		margin-top: 0.25rem !important;
	}
	.me-lg-1, .mx-lg-1 {
		margin-right: 0.25rem !important;
	}
	.mb-lg-1, .my-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.ms-lg-1, .mx-lg-1 {
		margin-left: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.mt-lg-2, .my-lg-2 {
		margin-top: 0.5rem !important;
	}
	.me-lg-2, .mx-lg-2 {
		margin-right: 0.5rem !important;
	}
	.mb-lg-2, .my-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.ms-lg-2, .mx-lg-2 {
		margin-left: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 0.75rem !important;
	}
	.mt-lg-3, .my-lg-3 {
		margin-top: 0.75rem !important;
	}
	.me-lg-3, .mx-lg-3 {
		margin-right: 0.75rem !important;
	}
	.mb-lg-3, .my-lg-3 {
		margin-bottom: 0.75rem !important;
	}
	.ms-lg-3, .mx-lg-3 {
		margin-left: 0.75rem !important;
	}
	.m-lg-4 {
		margin: 1rem !important;
	}
	.mt-lg-4, .my-lg-4 {
		margin-top: 1rem !important;
	}
	.me-lg-4, .mx-lg-4 {
		margin-right: 1rem !important;
	}
	.mb-lg-4, .my-lg-4 {
		margin-bottom: 1rem !important;
	}
	.ms-lg-4, .mx-lg-4 {
		margin-left: 1rem !important;
	}
	.m-lg-5 {
		margin: 1.5rem !important;
	}
	.mt-lg-5, .my-lg-5 {
		margin-top: 1.5rem !important;
	}
	.me-lg-5, .mx-lg-5 {
		margin-right: 1.5rem !important;
	}
	.mb-lg-5, .my-lg-5 {
		margin-bottom: 1.5rem !important;
	}
	.ms-lg-5, .mx-lg-5 {
		margin-left: 1.5rem !important;
	}
	.m-lg-6 {
		margin: 2rem !important;
	}
	.mt-lg-6, .my-lg-6 {
		margin-top: 2rem !important;
	}
	.me-lg-6, .mx-lg-6 {
		margin-right: 2rem !important;
	}
	.mb-lg-6, .my-lg-6 {
		margin-bottom: 2rem !important;
	}
	.ms-lg-6, .mx-lg-6 {
		margin-left: 2rem !important;
	}
	.m-lg-7 {
		margin: 3rem !important;
	}
	.mt-lg-7, .my-lg-7 {
		margin-top: 3rem !important;
	}
	.me-lg-7, .mx-lg-7 {
		margin-right: 3rem !important;
	}
	.mb-lg-7, .my-lg-7 {
		margin-bottom: 3rem !important;
	}
	.ms-lg-7, .mx-lg-7 {
		margin-left: 3rem !important;
	}
	.m-lg-8 {
		margin: 4rem !important;
	}
	.mt-lg-8, .my-lg-8 {
		margin-top: 4rem !important;
	}
	.me-lg-8, .mx-lg-8 {
		margin-right: 4rem !important;
	}
	.mb-lg-8, .my-lg-8 {
		margin-bottom: 4rem !important;
	}
	.ms-lg-8, .mx-lg-8 {
		margin-left: 4rem !important;
	}
	.m-lg-9 {
		margin: 6rem !important;
	}
	.mt-lg-9, .my-lg-9 {
		margin-top: 6rem !important;
	}
	.me-lg-9, .mx-lg-9 {
		margin-right: 6rem !important;
	}
	.mb-lg-9, .my-lg-9 {
		margin-bottom: 6rem !important;
	}
	.ms-lg-9, .mx-lg-9 {
		margin-left: 6rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0, .py-lg-0 {
		padding-top: 0 !important;
	}
	.pe-lg-0, .px-lg-0 {
		padding-right: 0 !important;
	}
	.pb-lg-0, .py-lg-0 {
		padding-bottom: 0 !important;
	}
	.ps-lg-0, .px-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.pt-lg-1, .py-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pe-lg-1, .px-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pb-lg-1, .py-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-lg-1, .px-lg-1 {
		padding-left: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.pt-lg-2, .py-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pe-lg-2, .px-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pb-lg-2, .py-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-lg-2, .px-lg-2 {
		padding-left: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 0.75rem !important;
	}
	.pt-lg-3, .py-lg-3 {
		padding-top: 0.75rem !important;
	}
	.pe-lg-3, .px-lg-3 {
		padding-right: 0.75rem !important;
	}
	.pb-lg-3, .py-lg-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-lg-3, .px-lg-3 {
		padding-left: 0.75rem !important;
	}
	.p-lg-4 {
		padding: 1rem !important;
	}
	.pt-lg-4, .py-lg-4 {
		padding-top: 1rem !important;
	}
	.pe-lg-4, .px-lg-4 {
		padding-right: 1rem !important;
	}
	.pb-lg-4, .py-lg-4 {
		padding-bottom: 1rem !important;
	}
	.ps-lg-4, .px-lg-4 {
		padding-left: 1rem !important;
	}
	.p-lg-5 {
		padding: 1.5rem !important;
	}
	.pt-lg-5, .py-lg-5 {
		padding-top: 1.5rem !important;
	}
	.pe-lg-5, .px-lg-5 {
		padding-right: 1.5rem !important;
	}
	.pb-lg-5, .py-lg-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-lg-5, .px-lg-5 {
		padding-left: 1.5rem !important;
	}
	.p-lg-6 {
		padding: 2rem !important;
	}
	.pt-lg-6, .py-lg-6 {
		padding-top: 2rem !important;
	}
	.pe-lg-6, .px-lg-6 {
		padding-right: 2rem !important;
	}
	.pb-lg-6, .py-lg-6 {
		padding-bottom: 2rem !important;
	}
	.ps-lg-6, .px-lg-6 {
		padding-left: 2rem !important;
	}
	.p-lg-7 {
		padding: 3rem !important;
	}
	.pt-lg-7, .py-lg-7 {
		padding-top: 3rem !important;
	}
	.pe-lg-7, .px-lg-7 {
		padding-right: 3rem !important;
	}
	.pb-lg-7, .py-lg-7 {
		padding-bottom: 3rem !important;
	}
	.ps-lg-7, .px-lg-7 {
		padding-left: 3rem !important;
	}
	.p-lg-8 {
		padding: 4rem !important;
	}
	.pt-lg-8, .py-lg-8 {
		padding-top: 4rem !important;
	}
	.pe-lg-8, .px-lg-8 {
		padding-right: 4rem !important;
	}
	.pb-lg-8, .py-lg-8 {
		padding-bottom: 4rem !important;
	}
	.ps-lg-8, .px-lg-8 {
		padding-left: 4rem !important;
	}
	.p-lg-9 {
		padding: 6rem !important;
	}
	.pt-lg-9, .py-lg-9 {
		padding-top: 6rem !important;
	}
	.pe-lg-9, .px-lg-9 {
		padding-right: 6rem !important;
	}
	.pb-lg-9, .py-lg-9 {
		padding-bottom: 6rem !important;
	}
	.ps-lg-9, .px-lg-9 {
		padding-left: 6rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto, .my-lg-auto {
		margin-top: auto !important;
	}
	.me-lg-auto, .mx-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-auto, .my-lg-auto {
		margin-bottom: auto !important;
	}
	.ms-lg-auto, .mx-lg-auto {
		margin-left: auto !important;
	}
}
@media (min-width: 1280px) {
	.m-xl-0 {
		margin: 0 !important;
	}
	.mt-xl-0, .my-xl-0 {
		margin-top: 0 !important;
	}
	.me-xl-0, .mx-xl-0 {
		margin-right: 0 !important;
	}
	.mb-xl-0, .my-xl-0 {
		margin-bottom: 0 !important;
	}
	.ms-xl-0, .mx-xl-0 {
		margin-left: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.mt-xl-1, .my-xl-1 {
		margin-top: 0.25rem !important;
	}
	.me-xl-1, .mx-xl-1 {
		margin-right: 0.25rem !important;
	}
	.mb-xl-1, .my-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ms-xl-1, .mx-xl-1 {
		margin-left: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.mt-xl-2, .my-xl-2 {
		margin-top: 0.5rem !important;
	}
	.me-xl-2, .mx-xl-2 {
		margin-right: 0.5rem !important;
	}
	.mb-xl-2, .my-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ms-xl-2, .mx-xl-2 {
		margin-left: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 0.75rem !important;
	}
	.mt-xl-3, .my-xl-3 {
		margin-top: 0.75rem !important;
	}
	.me-xl-3, .mx-xl-3 {
		margin-right: 0.75rem !important;
	}
	.mb-xl-3, .my-xl-3 {
		margin-bottom: 0.75rem !important;
	}
	.ms-xl-3, .mx-xl-3 {
		margin-left: 0.75rem !important;
	}
	.m-xl-4 {
		margin: 1rem !important;
	}
	.mt-xl-4, .my-xl-4 {
		margin-top: 1rem !important;
	}
	.me-xl-4, .mx-xl-4 {
		margin-right: 1rem !important;
	}
	.mb-xl-4, .my-xl-4 {
		margin-bottom: 1rem !important;
	}
	.ms-xl-4, .mx-xl-4 {
		margin-left: 1rem !important;
	}
	.m-xl-5 {
		margin: 1.5rem !important;
	}
	.mt-xl-5, .my-xl-5 {
		margin-top: 1.5rem !important;
	}
	.me-xl-5, .mx-xl-5 {
		margin-right: 1.5rem !important;
	}
	.mb-xl-5, .my-xl-5 {
		margin-bottom: 1.5rem !important;
	}
	.ms-xl-5, .mx-xl-5 {
		margin-left: 1.5rem !important;
	}
	.m-xl-6 {
		margin: 2rem !important;
	}
	.mt-xl-6, .my-xl-6 {
		margin-top: 2rem !important;
	}
	.me-xl-6, .mx-xl-6 {
		margin-right: 2rem !important;
	}
	.mb-xl-6, .my-xl-6 {
		margin-bottom: 2rem !important;
	}
	.ms-xl-6, .mx-xl-6 {
		margin-left: 2rem !important;
	}
	.m-xl-7 {
		margin: 3rem !important;
	}
	.mt-xl-7, .my-xl-7 {
		margin-top: 3rem !important;
	}
	.me-xl-7, .mx-xl-7 {
		margin-right: 3rem !important;
	}
	.mb-xl-7, .my-xl-7 {
		margin-bottom: 3rem !important;
	}
	.ms-xl-7, .mx-xl-7 {
		margin-left: 3rem !important;
	}
	.m-xl-8 {
		margin: 4rem !important;
	}
	.mt-xl-8, .my-xl-8 {
		margin-top: 4rem !important;
	}
	.me-xl-8, .mx-xl-8 {
		margin-right: 4rem !important;
	}
	.mb-xl-8, .my-xl-8 {
		margin-bottom: 4rem !important;
	}
	.ms-xl-8, .mx-xl-8 {
		margin-left: 4rem !important;
	}
	.m-xl-9 {
		margin: 6rem !important;
	}
	.mt-xl-9, .my-xl-9 {
		margin-top: 6rem !important;
	}
	.me-xl-9, .mx-xl-9 {
		margin-right: 6rem !important;
	}
	.mb-xl-9, .my-xl-9 {
		margin-bottom: 6rem !important;
	}
	.ms-xl-9, .mx-xl-9 {
		margin-left: 6rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0, .py-xl-0 {
		padding-top: 0 !important;
	}
	.pe-xl-0, .px-xl-0 {
		padding-right: 0 !important;
	}
	.pb-xl-0, .py-xl-0 {
		padding-bottom: 0 !important;
	}
	.ps-xl-0, .px-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.pt-xl-1, .py-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pe-xl-1, .px-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xl-1, .py-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-xl-1, .px-xl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.pt-xl-2, .py-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pe-xl-2, .px-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xl-2, .py-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-xl-2, .px-xl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 0.75rem !important;
	}
	.pt-xl-3, .py-xl-3 {
		padding-top: 0.75rem !important;
	}
	.pe-xl-3, .px-xl-3 {
		padding-right: 0.75rem !important;
	}
	.pb-xl-3, .py-xl-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-xl-3, .px-xl-3 {
		padding-left: 0.75rem !important;
	}
	.p-xl-4 {
		padding: 1rem !important;
	}
	.pt-xl-4, .py-xl-4 {
		padding-top: 1rem !important;
	}
	.pe-xl-4, .px-xl-4 {
		padding-right: 1rem !important;
	}
	.pb-xl-4, .py-xl-4 {
		padding-bottom: 1rem !important;
	}
	.ps-xl-4, .px-xl-4 {
		padding-left: 1rem !important;
	}
	.p-xl-5 {
		padding: 1.5rem !important;
	}
	.pt-xl-5, .py-xl-5 {
		padding-top: 1.5rem !important;
	}
	.pe-xl-5, .px-xl-5 {
		padding-right: 1.5rem !important;
	}
	.pb-xl-5, .py-xl-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-xl-5, .px-xl-5 {
		padding-left: 1.5rem !important;
	}
	.p-xl-6 {
		padding: 2rem !important;
	}
	.pt-xl-6, .py-xl-6 {
		padding-top: 2rem !important;
	}
	.pe-xl-6, .px-xl-6 {
		padding-right: 2rem !important;
	}
	.pb-xl-6, .py-xl-6 {
		padding-bottom: 2rem !important;
	}
	.ps-xl-6, .px-xl-6 {
		padding-left: 2rem !important;
	}
	.p-xl-7 {
		padding: 3rem !important;
	}
	.pt-xl-7, .py-xl-7 {
		padding-top: 3rem !important;
	}
	.pe-xl-7, .px-xl-7 {
		padding-right: 3rem !important;
	}
	.pb-xl-7, .py-xl-7 {
		padding-bottom: 3rem !important;
	}
	.ps-xl-7, .px-xl-7 {
		padding-left: 3rem !important;
	}
	.p-xl-8 {
		padding: 4rem !important;
	}
	.pt-xl-8, .py-xl-8 {
		padding-top: 4rem !important;
	}
	.pe-xl-8, .px-xl-8 {
		padding-right: 4rem !important;
	}
	.pb-xl-8, .py-xl-8 {
		padding-bottom: 4rem !important;
	}
	.ps-xl-8, .px-xl-8 {
		padding-left: 4rem !important;
	}
	.p-xl-9 {
		padding: 6rem !important;
	}
	.pt-xl-9, .py-xl-9 {
		padding-top: 6rem !important;
	}
	.pe-xl-9, .px-xl-9 {
		padding-right: 6rem !important;
	}
	.pb-xl-9, .py-xl-9 {
		padding-bottom: 6rem !important;
	}
	.ps-xl-9, .px-xl-9 {
		padding-left: 6rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto, .my-xl-auto {
		margin-top: auto !important;
	}
	.me-xl-auto, .mx-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-auto, .my-xl-auto {
		margin-bottom: auto !important;
	}
	.ms-xl-auto, .mx-xl-auto {
		margin-left: auto !important;
	}
}
/*------- Alignments & values-------*/

.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-justify {
	text-align: justify !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.text-start {
	text-align: left !important;
}
.text-end {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}
@media (min-width: 576px) {
	.text-sm-start {
		text-align: left !important;
	}
	.text-sm-end {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}
@media (min-width: 768px) {
	.text-md-start {
		text-align: left !important;
	}
	.text-md-end {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}
@media (min-width: 992px) {
	.text-lg-start {
		text-align: left !important;
	}
	.text-lg-end {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}
@media (min-width: 1280px) {
	.text-xl-start {
		text-align: left !important;
	}
	.text-xl-end {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}
.text-lowercase {
	text-transform: lowercase !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-capitalize {
	text-transform: capitalize !important;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}
.font-weight-semibold {
	font-weight: 600 !important;
}
.font-weight-bold {
	font-weight: bold !important;
}
.font-italic {
	font-style: italic !important;
}
.text-white {
	color: #f0f0f0 !important;
}
.text-primary {
	color: $primary !important;
}
a.text-primary {
	&:hover, &:focus {
		color: #5e69b8 !important;
	}
}
.text-secondary {
	color: $secondary !important;
}
a.text-secondary {
	&:hover, &:focus {
		color: $secondary !important;
	}
}
.text-success {
	color: $success !important;
}
a.text-success {
	&:hover, &:focus {
		color: #15ca7c !important;
	}
}
.text-info {
	color: $info !important;
}
a.text-info {
	&:hover, &:focus {
		color: #25a1c0 !important;
	}
}
.text-warning {
	color: $warning !important;
}
a.text-warning {
	&:hover, &:focus {
		color: #c29d0b !important;
	}
}
.text-danger {
	color: $danger !important;
}
a.text-danger {
	&:hover, &:focus {
		color: #df0812 !important;
	}
}
.text-light {
	color: #f8f9fa !important;
}
a.text-light {
	&:hover, &:focus {
		color: #dae0e5 !important;
	}
}
.text-dark {
	color: $color !important;
}
a.text-dark {
	&:hover, &:focus {
		color: #384b6e !important;
	}
}
.text-body {
	color: #495057 !important;
}
.text-muted {
	color: #728096 !important;
}
.icon-muted {
	color: #e5e9f2 !important;
}
.text-black-50 {
	color: $black-5 !important;
}
.text-white-50 {
	color: $white-5 !important;
}
.text-white-80 {
	color: $white-8 !important;
}
.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.visible {
	visibility: visible !important;
}
.invisible {
	visibility: hidden !important;
}
@media print {
	* {
		text-shadow: none !important;
		box-shadow: none !important;
		&::before, &::after {
			text-shadow: none !important;
			box-shadow: none !important;
		}
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap !important;
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	blockquote {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr, img {
		page-break-inside: avoid;
	}
	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}
	h2, h3 {
		page-break-after: avoid;
	}
	@page {
		size: a3;
	}
	body, .container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid $black;
	}
	.table, .text-wrap table {
		border-collapse: collapse !important;
	}
	.table td, .text-wrap table td, .table th, .text-wrap table th {
		background-color: $white !important;
	}
	.table-bordered th, .text-wrap table th, .table-bordered td, .text-wrap table td {
		border: $border!important;
	}
}
.lead {
	line-height: 1.4;
}
a {
	-webkit-text-decoration-skip: ink;
	text-decoration-skip: ink;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
	color: inherit;
}
strong, b {
	font-weight: 600;
}
p, ul, ol {
	margin-bottom: 1em;
}
blockquote {
	margin-bottom: 1em;
	font-style: italic;
	color: #6e7687;
	padding-left: 2rem;
	border-left: 2px solid rgba(0, 40, 100, 0.12);
	p {
		margin-bottom: 1rem;
	}
	cite {
		display: block;
		text-align: right;
		&:before {
			content: 'â€” ';
		}
	}
}
code {
	background: #eff0f6;
	border: 1px solid #eff0f6;
	border-radius: 3px;
	padding: 3px;
}
pre code {
	padding: 0;
	border-radius: 0;
	border: none;
	background: none;
}
hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
pre {
	color: $info;
	padding: 1.5rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	border-left: 3px solid $primary;
	background-color: #f8fafc;
	border-radius: 3px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}
img {
	max-width: 100%;
}
.text-wrap {
	font-size: 14px;
	line-height: 1.66;
	> {
		:first-child {
			margin-top: 0;
		}
		:last-child {
			margin-bottom: 0;
		}
		h1, h2, h3, h4, h5, h6 {
			margin-top: 1em;
		}
	}
}
.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid #eff0f6;
	border-radius: 3px;
	list-style: none;
	&:before {
		content: 'Table of contents:';
		display: block;
		font-weight: 600;
	}
}
@media print {
	.container {
		max-width: none;
	}
}
.row-cards> {
	.col, [class*='col-'] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.row-deck> {
	.col, [class*='col-'] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: stretch;
		align-items: stretch;
	}
	.col .card, [class*='col-'] .card {
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
}
.col-text {
	max-width: 48rem;
}
.col-login {
	max-width: 24rem;
}
.gutters-0 {
	margin-right: 0;
	margin-left: 0;
	> {
		.col, [class*="col-"] {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.card {
		margin-bottom: 0;
	}
}
.gutters-xs {
	margin-right: -0.25rem;
	margin-left: -0.25rem;
	> {
		.col, [class*="col-"] {
			padding-right: 0.25rem;
			padding-left: 0.25rem;
		}
	}
	.card {
		margin-bottom: 0.5rem;
	}
}
.gutters-sm {
	margin-right: -0.5rem;
	margin-left: -0.5rem;
	> {
		.col, [class*="col-"] {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
		}
	}
	.card {
		margin-bottom: 1rem;
	}
}
.gutters-lg {
	margin-right: -1rem;
	margin-left: -1rem;
	> {
		.col, [class*="col-"] {
			padding-right: 1rem;
			padding-left: 1rem;
		}
	}
	.card {
		margin-bottom: 2rem;
	}
}
.gutters-xl {
	margin-right: -1.5rem;
	margin-left: -1.5rem;
	> {
		.col, [class*="col-"] {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}
	}
	.card {
		margin-bottom: 3rem;
	}
}
.page, .page-content {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
}
.app .page, .app .page-content {
	min-height: 100% !important;
}
body.fixed-header .page {
	padding-top: 4.5rem;
}
@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}
.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.page-content {
	margin: .75rem 0;
}
@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0;
	}
	.h-100vh .page-content {
		margin: 0;
	}
}
.pos-absolute {
	position: absolute;
	top: 0;
	border: 0;
	left: 0;
	right: 0;
	display: block;
	width: 100%;
	height: 100%;
}