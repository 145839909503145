body * {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        transition: .3s background;
    }
    &::-webkit-scrollbar-thumb,
    &:hover::-webkit-scrollbar-thumb {
        background: #c2cbdf;
    }
    &::-ms-webkit-scrollbar-track {
        background: $white;
    }
    &::-ms-webkit-scrollbar {
        width: 5px;
        height: 5px;
        transition: .3s background;
    }
    &::-ms-webkit-scrollbar-thumb,
    &:hover::-ms-webkit-scrollbar-thumb {
        background: #c2cbdf;
    }
    &::-ms-webkit-scrollbar-track {
        background: $white;
    }
}


/*------ Headers -------*/

.page-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 1.5rem 0 1.5rem;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    border-radius: 7px;
    position: relative;
    min-height: 50px;
    .breadcrumb-icon {
        margin-top: 3px;
        margin-left: 4px;
    }
    .breadcrumb-item {
        .svg-icon {
            width: 20px;
        }
        &:first-child {
            margin-top: 0;
        }
        margin-top: 3px;
    }
}

.page-title {
    margin: 0;
    font-size: 25px;
    font-weight: 500;
    line-height: 2.5rem;
    position: relative;
}

.page-title-icon {
    color: #9aa0ac;
    font-size: 1.25rem;
}

.page-subtitle {
    font-size: 0.8125rem;
    color: #6e7687;
    margin-left: 2rem;
    position: relative;
    a {
        color: inherit;
    }
}

.page-options {
    margin-left: auto;
}

.page-description {
    margin: .25rem 0 0;
    color: #6e7687;
    a {
        color: inherit;
    }
}

.page-single,
.page-single-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.content-heading {
    font-weight: 400;
    margin: 2rem 0 1.5rem;
    font-size: 1.25rem;
    line-height: 1.25;
    &:first-child {
        margin-top: 0;
    }
}

.aside {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 22rem;
    background: $white;
    border-left: 1px solid #eff0f6;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 100;
    visibility: hidden;
    box-shadow: 0 5px 20px $black-1;
}

@media (min-width: 1600px) {
    body.aside-opened .aside {
        visibility: visible;
    }
}

.aside-body {
    padding: 1.5rem;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: auto;
}

.aside-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid #eff0f6;
}

.aside-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #eff0f6;
}

.header {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background: $white;
    .dropdown-menu {
        margin-top: 0;
    }
    .mega-menu {
        width: 350px;
    }
}

.header-bg {
    background: #38a01e;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #38a01e, $primary);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #38a01e, $primary);
}

body.fixed-header .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
}

@media print {
    .header {
        display: none;
    }
    .footer {
        display: none;
    }
}

.nav-unread {
    position: absolute;
    top: .10rem;
    right: .4rem;
    background: #ecd938;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    &.badge {
        position: absolute;
        top: 0;
        text-align: center;
        right: 0;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
    }
}

.nav-link.icon {
    margin: 5px;
    padding: 12px;
    text-align: center;
    height: 2.5rem;
    font-size: 1.2rem;
    position: relative;
    border-radius: 3px;
    .nav-span {
        font-size: 0.9rem;
        color: #576482;
        vertical-align: middle;
        margin-left: 0.5rem;
        font-weight: 400;
    }
}

.header-brand {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
}

.app-header .header-brand {
    color: inherit;
    margin-right: 1rem;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    min-width: 220px;
    text-align: center;
    min-width: auto !important;
    .header-brand-img {
        margin-right: 0;
    }
}

.app-header1 .header-brand {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    min-width: 215px;
    text-align: center;
    .header-brand-img {
        margin-right: 0;
    }
}

.header-brand:hover {
    color: inherit;
    text-decoration: none;
}

.header-brand-img {
    height: 2.5rem;
    line-height: 2rem;
    vertical-align: bottom;
    margin-right: 0;
    width: auto;
}

.header-avatar {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
}

.header-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1rem;
}

.center-block {
    margin-right: auto;
    margin-left: auto;
    float: inherit !important;
}

.header-btn.has-new {
    position: relative;
    &:before {
        content: '';
        width: 6px;
        height: 6px;
        background: #dc0441;
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 50%;
    }
}

.header-toggler {
    width: 3rem;
    height: 3rem;
    position: relative;
    color: $black;
    &:hover {
        color: #32e448;
    }
}

.header-toggler-icon {
    position: absolute;
    width: 1rem;
    height: 2px;
    color: inherit;
    background: currentColor;
    border-radius: 3px;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -.5rem;
    box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

footer.footer {
    background: $white;
    font-size: 0.875rem;
    border-top: 0;
    padding: 1.25rem 0;
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px rgb(176, 184, 214);
}

.footer {
    background: $white;
    border-top: 1px solid #eff0f6;
    font-size: 0.875rem;
    padding: 1.25rem 0;
    .social {
        ul li {
            float: left;
            padding: 7px;
            a {
                color: #abb8c3;
            }
        }
        margin: 0 auto;
    }
    a:not(.btn) {
        color: #8e9cad;
        font-weight: 400;
    }
}

.br-100 {
    border-radius: 100% !important;
}

.br-3 {
    border-radius: 3px !important;
}

.br-7 {
    border-radius: 7px !important;
}

.br-tl-7 {
    border-top-left-radius: 7px !important;
}

.br-bl-7 {
    border-bottom-left-radius: 7px !important;
}

.br-tr-7 {
    border-top-right-radius: 7px !important;
}

.br-br-7 {
    border-bottom-right-radius: 7px !important;
}

.br-0 {
    border-radius: 0px;
}

.br-tl-0 {
    border-top-left-radius: 0px !important;
}

.br-bl-0 {
    border-bottom-left-radius: 0px !important;
}

.br-tr-0 {
    border-top-right-radius: 0px !important;
}

.br-br-0 {
    border-bottom-right-radius: 0px !important;
}

.list.d-flex img {
    width: 60px;
}

#popup {
    position: absolute;
    width: auto;
    height: 30px;
    background: #fe6b1f;
    display: none;
    color: white;
    border-radius: 5px;
}

#copy {
    background: none;
    color: white;
    font-weight: bold;
    padding: 8px 25px;
    border: 0;
}


/*------ Charts styles ------*/

.h-85 {
    height: 85px !important;
}

.chart-visitors {
    min-height: 18rem;
    overflow: hidden;
}

.chart-height {
    height: 20rem;
    overflow: hidden;
}

.chart-tasks {
    height: 15rem;
    overflow: hidden;
}

.chart-donut {
    height: 21rem;
    overflow: hidden;
}

.chart-pie {
    height: 22rem;
    overflow: hidden;
}

.chartsh {
    height: 16rem;
    overflow: hidden;
}

.chartwidget {
    height: 17rem;
    overflow: hidden;
}


/*-----Countdown-----*/

.countdown span:first-child {
    font-size: 30px;
    font-weight: 500;
}

.social {
    i {
        font-size: 40px;
    }
    .social-icon i {
        font-size: 14px;
    }
}

.instagram {
    background: #de497b;
}

.linkedin {
    background: #0077b5;
}

.twitter {
    background: #1da1f2;
}

.facebook {
    background: #3b5998;
}

.widget-info {
    i {
        width: 100px;
        height: 100px;
        padding: 27px 0;
        text-align: center;
        border: 2px solid $white;
        border-radius: 50%;
    }
    a {
        border-bottom: 1px solid $white;
    }
}

.about-img {
    background: url('../images/about.jpg');
    background-size: cover;
    background-position: center;
}

.login-img {
    background: url('../images/login.jpg');
    background-size: cover;
}

.construction-img {
    background: url('../images/construction.jpg');
    background-size: cover;
}

.z-index-10 {
    z-index: 10 !important;
}

.card-blog-overlay .card-header {
    border-bottom: 1px solid $white-2;
}

.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
    border-top: 1px solid $white-2;
}

.card-blog-overlay {
    background: url('../images/photos/thumb2.jpg');
    background-size: cover;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        background: $black-5;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        border-radius: 7px;
    }
}

.card-blog-overlay1:before,
.card-blog-overlay2:before {
    content: '';
    display: block;
    position: absolute;
    background: $black-5;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    border-radius: 7px;
}

.card-blog-overlay1 {
    background: url('../images/photos/thumb3.jpg');
    background-size: cover;
}

.card-blog-overlay2 {
    background: url('../images/photos/22.jpg');
    background-size: cover;
}

.login-transparent {
    background: url('../images/photos/22.jpg');
    background-size: cover;
    &:before {
        content: '';
        display: block;
        position: absolute;
        background: -webkit-gradient(linear, left top, right top, from(rgba(159, 120, 255, 0.96)), to(rgba(50, 202, 254, 0.96)));
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        border-radius: 7px;
    }
}

.page-breadcrumb {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    background: none;
    padding: 0;
    margin: 1rem 0 0;
    font-size: 0.875rem;
}

@media (min-width: 768px) {
    .page-breadcrumb {
        margin: -.5rem 0 0;
    }
}

@media (max-width: 320px) {
    .fc-toolbar .fc-right {
        float: right;
        width: 100%;
        text-align: center;
        margin: 10px 0;
    }
}

.page-breadcrumb .breadcrumb-item {
    color: #9aa0ac;
    &.active {
        color: #6e7687;
    }
}

.pagination-simple .page-item {
    .page-link {
        background: none;
        border: none;
    }
    &.active .page-link {
        color: #495057;
        font-weight: 700;
    }
}

.pagination-pager {
    .page-prev {
        margin-right: auto;
    }
    .page-next {
        margin-left: auto;
    }
}

.page-total-text {
    margin-right: 1rem;
    -ms-flex-item-align: center;
    align-self: center;
    color: #6e7687;
}


/*-------- Search ---------*/

.search-element {
    position: relative;
}

.header .form-inline {
    .form-control {
        border: 1px solid #D3DFEA;
        padding-left: 20px;
        width: 500px;
        padding-right: 40px;
        padding-top: 7px;
        background: #f4f5fc;
        &:focus {
            box-shadow: none;
        }
    }
    .btn {
        border-radius: 0;
        padding: 5px 15px 6px 15px;
        border: 2px solid transparent;
        box-shadow: none;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        background: transparent;
        color: $white;
        margin-left: -56px;
        font-size: 15px;
        position: absolute;
        right: 0;
        i {
            color: #818c9b;
        }
    }
}

@media (max-width: 767.98px) and (min-width: 576px) {
    .header .form-inline .search-element {
        position: absolute;
        top: 8px;
        left: 10px;
        right: 10px;
        z-index: 892;
        display: none;
    }
    body.search-show .header .form-inline .search-element {
        display: block;
    }
    .header .navsearch i {
        font-size: 20px;
        color: $white;
    }
    .search-element .form-control {
        float: left;
        width: 100% !important;
        height: 40px;
        margin: 0 auto;
        background: $white !important;
        color: $color;
        border: 1px solid #eff0f6;
    }
    .search-show .search-element {
        display: block;
        background: $primary;
        left: 0 !important;
        top: 0 !important;
        right: 0;
        width: 100%;
        padding: 18px 13px;
    }
    .header {
        .form-inline .form-control::-webkit-input-placeholder {
            color: #b3bdca !important;
        }
        .navsearch i {
            font-size: 20px;
            color: $color;
            padding-top: 5px;
        }
        .form-inline .btn {
            color: #46494a !important;
            padding: 8px 15px 6px 15px;
            position: unset !important;
        }
    }
    .animated-arrow.hor-toggle {
        text-align: center;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1.2rem;
        position: relative;
        border: 0px solid #eff0f6;
        border-radius: 3px;
        margin: 5px;
        top: 0;
        span {
            margin-left: 8px;
            margin-top: 2px;
            vertical-align: middle;
        }
    }
}

@media (max-width: 1024px) {
    .app.sidebar-gone.search-show .header.top-header {
        z-index: 1036;
    }
    body {
        &.search-show,
        &.sidebar-show {
            overflow: hidden;
        }
        &.search-show:before,
        &.sidebar-show:before {
            content: '';
            position: fixed;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: $black-4;
            opacity: .6;
            z-index: 9;
        }
    }
}

@media (min-width: 575.98px) and (max-width: 1200px) {
    .header .form-inline .form-control {
        width: 200px;
    }
}

@media (max-width: 575.98px) {
    .header .form-inline .search-element .form-control {
        float: left;
        width: 100%;
        height: 40px;
        margin: 0 auto;
        background: $white;
        border-radius: 4px;
    }
    .search-show .search-element {
        display: block !important;
        background: $primary;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        padding: 20px 13px;
    }
    .form-control.header-search {
        color: $black;
    }
    .header {
        .form-inline {
            .search-element {
                position: absolute;
                z-index: 892;
                display: none;
            }
            .btn {
                color: #46494a !important;
            }
            .form-control::-webkit-input-placeholder {
                color: #b3bdca !important;
            }
        }
        .navsearch i {
            font-size: 20px;
            color: $color;
            padding-top: 4px;
        }
    }
    .header-brand-img {
        &.desktop-lgo,
        &.dark-logo {
            display: none;
        }
    }
    .header-brand {
        margin-right: 0;
    }
    .header-brand-img {
        &.mobile-logo {
            display: block;
            margin-left: 0.5rem;
        }
        &.darkmobile-logo {
            display: none;
            margin-left: 0.5rem;
        }
    }
    .animated-arrow.hor-toggle {
        text-align: center;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1.2rem;
        position: relative;
        border: 0;
        border-radius: 3px;
        margin: 5px;
        top: 0;
        span {
            margin-left: 8px;
            margin-top: 2px;
            vertical-align: middle;
        }
    }
}

.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    >li {
        -ms-flex: 1 0 4rem;
        flex: 1 0 4rem;
    }
}

.icons-list-wrap {
    overflow: hidden;
}

.icons-list-item {
    text-align: center;
    height: 4rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #eff0f6;
    margin: 4px;
    border-radius: 5px;
    background: #f7f7f9;
    i {
        font-size: 1.25rem;
    }
}

.img-gallery {
    margin-right: -.25rem;
    margin-left: -.25rem;
    margin-bottom: -.5rem;
    > {
        .col,
        [class*="col-"] {
            padding-left: .25rem;
            padding-right: .25rem;
            padding-bottom: .5rem;
        }
    }
}

.link-overlay {
    position: relative;
    &:hover .link-overlay-bg {
        opacity: 1;
    }
}

.link-overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(70, 127, 207, 0.8);
    display: -ms-flexbox;
    display: flex;
    color: $white;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.25rem;
    opacity: 0;
    transition: .3s opacity;
}

.media-icon {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    border-radius: 100%;
    font-size: 18px;
}

.media-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

textarea[cols] {
    height: auto;
}


/* --------Added--------- css*/

.wizard-card .moving-tab {
    margin-top: 5px;
    background-color: $primary !important;
}

.mb-3 label.control-label {
    color: $primary;
}

.wizard-card.mb-3 .form-control {
    background-image: linear-gradient(#c4c4c4, #c4c4c4, linear-gradient(#e1e1e2, #e1e1e2));
}

.wizard-container .wizard-navigation {
    background: #f4f5fb;
}

.wizard-card .choice {
    &:hover .icon,
    &.active .icon {
        border-color: $primary !important;
        color: $primary !important;
    }
}

.richText {
    .richText-toolbar {
        ul li a {
            border-right: #d3dfea solid 1px;
        }
        border-bottom: #d3dfea solid 1px;
        border-top: 1px solid #d3dfea;
    }
    border: #d3dfea solid 1px;
    background-color: #f3f4f7;
    border-top: 0;
    border-bottom: 0;
    .richText-undo,
    .richText-redo {
        border-right: #d3dfea solid 1px !important;
    }
}

.addui-slider .addui-slider-track {
    .addui-slider-range {
        display: block;
        background: $primary;
    }
    .addui-slider-handle {
        &:after {
            background: $primary;
        }
        &:hover {
            box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
            .addui-slider-value {
                background: rgba(159, 120, 255, 0.5);
            }
        }
        &.addui-slider-handle-active {
            background: rgba(159, 120, 255, 0.5);
            box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
            .addui-slider-value {
                background: rgba(159, 120, 255, 0.75);
            }
        }
    }
}

.cal1 {
    .clndr {
        .clndr-table {
            .header-days {
                .header-day {
                    border-left: 1px solid rgba(23, 183, 148, 0.1);
                    border-top: 1px solid #e2e3e4;
                }
                background: $primary;
            }
            tr {
                .empty,
                .adjacent-month,
                .my-empty,
                .my-adjacent-month {
                    border-left: 1px solid #eff0f6;
                    border-top: 1px solid #eff0f6;
                    background: #f4f5fb;
                }
                .day {
                    &.event,
                    &.my-event {
                        background: #eeeef5;
                    }
                    &.today.event,
                    &.my-today.event {
                        background: #f2ecff;
                    }
                    &.event:hover,
                    &.my-event:hover {
                        background: #e5e9f2 !important;
                    }
                    &.today,
                    &.my-today {
                        background: #e5e9f2 !important;
                        color: $primary !important;
                    }
                    border-left: 1px solid #eff0f6;
                    border-top: 1px solid #eff0f6;
                    &:last-child {
                        border-right: 1px solid #eff0f6;
                    }
                    &:hover {
                        background: #e5e9f2;
                    }
                }
            }
        }
        .clndr-controls .clndr-control-button {
            .clndr-previous-button {
                color: $primary;
                background: #f4f5fb;
            }
            .clndr-next-button {
                color: $primary;
                background: #f4f5fb;
                &:hover {
                    background: $primary;
                }
            }
            .clndr-previous-button:hover {
                background: $primary;
            }
        }
    }
    font-size: 14px;
    border: 1px solid #eff0f6;
}

.fc button {
    background: #f3f4f7;
    color: #efefef;
    margin: 2px 1px !important;
    text-transform: uppercase;
    font-size: 13px !important;
    font-weight: 500;
}

.fc-unthemed .fc-divider {
    border-color: #eff0f6;
}

.fc-event {
    border: 1px solid #f8f5ff;
    background-color: $primary;
}

.fc-event-dot {
    background-color: $primary;
}

.fc-unthemed {
    .fc-divider,
    .fc-list-heading td,
    .fc-popover .fc-header {
        background: #f4f5fb;
    }
}

.fc-toolbar {
    .fc-state-active,
    .ui-state-active {
        background: $primary;
        color: $white;
    }
}

.fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
    border: none !important;
    box-shadow: none !important;
}

.fc-unthemed .fc-list-item:hover td {
    background-color: #f4f5fb;
}

.conv-form-wrapper div#messages div.message.to {
    background: rgb(238, 239, 246);
}

div.conv-form-wrapper div.options div.option {
    border: 1px solid $primary;
    background: $white;
    color: $primary;
}

form.convFormDynamic button.submit {
    color: $primary;
    border: 1px solid $primary;
    background: $white;
}

div.conv-form-wrapper div.options div.option.selected {
    background: $primary;
    color: $white;
    &:hover {
        background: $primary;
    }
}

form.convFormDynamic button.submit:hover {
    background: $primary;
    color: $white;
}

div.conv-form-wrapper div#messages div.message.from {
    background: $primary;
    color: $white;
    border-top-right-radius: 0;
}

#sidebar ul li.active>a {
    color: $primary;
    &:hover {
        background: #6d4dbb;
        color: $white;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.accordionjs .acc_section.acc_active>.acc_head {
    background: $primary !important;
}

.user-profile .user-pro-body .u-dropdown {
    color: $white;
}

#sidebar {
    ul li {
        a {
            color: #384056;
            font-weight: 400;
        }
        position: relative;
        ul li:last-child {
            border-bottom: 0;
        }
    }
    li a {
        &[aria-expanded="true"],
        &.active {
            background: $primary;
            color: $white;
        }
        &[aria-expanded="true"]:hover {
            color: $white !important;
        }
    }
}

.sweet-alert button {
    background-color: $primary !important;
}

.timeline__item:after {
    border: 6px solid $primary;
}

.timeline__content {
    background-color: #eaf0f9;
}

.tab_wrapper {
    >ul li.active {
        border-color: $primary !important;
        background: $primary !important;
        color: $white;
    }
    &.right_side>ul li.active:after {
        background: $primary !important;
    }
    >ul {
        li {
            border: 1px solid #eff0f6;
            border-top: 3px solid 1px solid #eff0f6;
        }
        border-bottom: 1px solid #eff0f6;
    }
    &.right_side {
        .content_wrapper {
            border: 1px solid #eff0f6;
        }
        >ul {
            li {
                &.active {
                    border-color: 1px solid #eff0f6;
                }
                &:after {
                    background: 1px solid #eff0f6;
                }
            }
            border-bottom: 1px solid #eff0f6;
        }
    }
}

.job-box-filter {
    padding: 12px 15px;
    border-bottom: 1px solid #eff0f6;
    a.filtsec {
        margin-top: 8px;
        display: inline-block;
        margin-right: 15px;
        padding: 4px 10px;
        transition: all ease 0.4s;
        background: #edf0f3;
        border-radius: 50px;
        font-size: 13px;
        color: #81a0b1;
        border: 1px solid #e2e8ef;
        &.active {
            color: $white;
            background: #16262c;
            border-color: #16262c;
        }
        i {
            color: $primary;
            margin-right: 5px;
        }
        &:hover,
        &:focus {
            color: $white;
            background: #07b107;
            border-color: #07b107;
        }
        &:hover i,
        &:focus i {
            color: $white;
        }
    }
    h4 i {
        margin-right: 10px;
    }
    label {
        width: 100%;
    }
    select.input-sm {
        display: inline-block;
        max-width: 120px;
        margin: 0 5px;
        border: 1px solid #eff0f6;
        border-radius: 2px;
        height: 34px;
        font-size: 15px;
    }
    label input.form-control {
        max-width: 200px;
        display: inline-block;
        border: 1px solid #eff0f6;
        border-radius: 2px;
        margin-left: 5px;
        font-size: 15px;
    }
}

.text-right {
    text-align: right;
}

.job-box {
    display: inline-block;
    width: 100%;
    padding: 0px;
}


/*--- img container---*/

.image_outer_container {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    position: relative;
}

.image_inner_container {
    border-radius: 50%;
    padding: 5px;
    img {
        height: 150px;
        width: 150px;
        border-radius: 50%;
        border: 5px solid white;
    }
}

.image_outer_container .green_icon {
    background-color: #4cd137;
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 30px;
    width: 30px;
    border: 5px solid white;
    border-radius: 50%;
}

.search {
    position: absolute;
    width: 320px;
    svg {
        transform: translateX(-126px);
        width: 180px;
        height: auto;
        stroke-width: 8px;
        stroke: $white;
        stroke-width: 1px;
        stroke-dashoffset: 0;
        stroke-dasharray: 64.6 206.305;
        transition: all 0.5s ease-in-out;
        margin-top: 5px;
    }
}

.input-search {
    position: absolute;
    width: calc(100% - 148px);
    height: 34px;
    top: 0;
    right: 20px;
    bottom: 0;
    left: 0;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 20px;
    font-size: 16px;
    color: $white;
}

.search-label {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    top: 0;
    left: 50%;
    margin-left: -54px;
    z-index: 100;
    transition: 0.5s ease-in-out;
}

.isActive {
    .search-label {
        transform: translateX(246px);
    }
    svg,
    &.full svg {
        stroke-dashoffset: -65;
        stroke-dasharray: 141.305 65;
        transform: translateX(0);
    }
}

.full {
    .search-label {
        transform: translateX(246px);
    }
    svg {
        stroke-dashoffset: 0;
        stroke-dasharray: 64.6 206.305;
        transform: translateX(0);
    }
}

#drag-1,
#drag-2 {
    width: 25%;
    height: 100%;
    min-height: 6.5em;
    margin: 10%;
    background-color: #29e;
    color: white;
    border-radius: 0.75em;
    padding: 4%;
    user-select: none;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}

.search-inline {
    width: 100%;
    left: 0;
    padding: 33px 0;
    top: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background-color: $white;
    z-index: 9;
    transition: all .5s ease-in-out;
    &.search-visible {
        opacity: 1;
        visibility: visible;
        animation: fadeInDown 0.2s ease-in-out;
    }
    button[type="submit"] {
        position: absolute;
        right: 52px;
        top: 0;
        background-color: transparent;
        border: 0px;
        top: 0;
        right: 80px;
        padding: 0px;
        cursor: pointer;
        width: 80px;
        height: 100%;
        background: $primary;
        color: $white;
    }
    .form-control {
        border: 0px;
        padding-left: 0;
        font-size: 20px;
        position: absolute;
        left: 1%;
        top: 0;
        height: 100%;
        width: 99%;
        outline: none;
        &:focus {
            box-shadow: none;
        }
    }
    .search-close {
        position: absolute;
        top: 0;
        right: 0;
        color: #616161;
        width: 80px;
        height: 100%;
        text-align: center;
        display: table;
        background: #efefef;
        text-decoration: none;
        i {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.searching i {
    font-size: 18px;
}

.search-close i {
    padding: 24px 0;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.lockscreen {
    background: inherit;
    width: 300px;
    height: 280px;
    overflow: hidden;
    &:before {
        content: '';
        width: 350px;
        height: 400px;
        background: inherit;
        position: absolute;
        left: -25px;
        right: 0;
        top: -25px;
        bottom: 0;
        box-shadow: inset 0 0 0 200px $white-4;
        filter: blur(1px);
    }
}

.table-stats table {
    th,
    td {
        border: none;
        border-bottom: 1px solid #e8e9ef;
        font-size: 12px;
        font-weight: normal;
        padding: .75em 1.25em;
        text-transform: uppercase;
    }
    th img,
    td img {
        margin-right: 10px;
        max-width: 45px;
    }
    th .name {
        font-size: 14px;
        text-transform: capitalize;
    }
    td {
        .name {
            font-size: 14px;
            text-transform: capitalize;
        }
        font-size: 14px;
        text-transform: capitalize;
        vertical-align: middle;
    }
}

.traffic-sales-content {
    padding: 14px;
}

.traffic-sales-amount {
    float: right;
}

.pulse {
    display: block;
    position: absolute;
    top: .1rem;
    right: .7rem;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $secondary;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(247, 45, 102, 0.9);
    animation: pulse 2s infinite;
    &:hover {
        animation: none;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
        box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.7);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
        box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
        box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
    }
}

@media (max-width: 992px) {
    .header.top-header {
        .header-option,
        .header-setting {
            display: none;
        }
    }
    .horizontal-main.hor-menu {
        border-bottom: 0;
        z-index: 9;
    }
    .page-body {
        padding-top: 1px;
    }
    .app-content.page-body .container,
    .header.top-header .container {
        max-width: none;
    }
    .active .header.top-header {
        z-index: 1035;
    }
    .app-content.page-body .side-app {
        padding: 6.2rem 0.75rem 0 0.75rem !important;
    }
    .navsearch {
        margin: 5px;
        padding: 0px;
        text-align: center;
        height: 2.5rem;
        font-size: 1.2rem;
        position: relative;
        border: 0px solid #eff0f6;
        border-radius: 3px;
        i {
            padding-top: 0 !important;
        }
    }
    .app-content.page-body {
        margin-top: 4.7rem;
    }
    .comb-page .app-content.page-body {
        margin-top: 0;
    }
    .media.profile-footer .media-user {
        display: none;
    }
}

.header.top-header {
    top: 0;
    position: fixed;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px rgb(176, 184, 214);
    .search-element input {
        border-radius: 6em;
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    .header-brand-img {
        &.desktop-lgo,
        &.dark-logo {
            margin-left: 0rem;
        }
    }
    .animated-arrow.hor-toggle {
        text-align: center;
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1.2rem;
        position: relative;
        border: 0px solid #eff0f6;
        border-radius: 3px;
        margin: 5px;
        top: 0;
        span {
            margin-left: 8px;
            margin-top: 6px;
            vertical-align: middle;
        }
    }
}

.activity {
    position: relative;
    border-left: 3px solid #eff0f6;
    margin-left: 16px;
    .img-activity {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 44px;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        border: 3px solid;
    }
    .item-activity {
        margin-left: 40px;
        margin-bottom: 26px;
    }
}

#AreaChart2 {
    width: 105% !important;
    height: 88px !important;
    bottom: -20px;
    position: relative;
    left: -7px;
}

#CryptoChart,
#CryptoChart1,
#CryptoChart2,
#CryptoChart3 {
    width: 105% !important;
    height: 70px !important;
    bottom: -15px;
    position: relative;
    left: -7px;
}


/* News ticker */

[class*="js-conveyor-"] ul li {
    padding: 9px 15px;
    /* line-height: 47px; */
    border: 1px solid #efefef;
    margin-right: 5px;
    background: $white;
    margin: 15px 6px;
    border-radius: 5px;
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px #b0b8d6;
}

.news-crypto span {
    font-size: 0.8rem;
}


/*latetes news timeline */

.latest-timeline ul.timeline {
    list-style-type: none;
    position: relative;
    &:before {
        content: ' ';
        background: #eff0f6;
        display: inline-block;
        position: absolute;
        left: 15px;
        width: 3px;
        height: 100%;
        z-index: 1;
    }
    >li {
        margin: 20px 0;
        padding-left: 3rem;
        &:before {
            content: ' ';
            background: white;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid #1753fc;
            left: 6px;
            width: 20px;
            height: 20px;
            z-index: 1;
        }
        &:first-child:before {
            border: 3px solid #0dd157;
        }
        &:nth-child(2):before {
            border: 3px solid #2d9eef;
        }
        &:nth-child(3):before {
            border: 3px solid #f35e90;
        }
        &:nth-child(4):before {
            border: 3px solid $orange;
        }
        &:last-child:before {
            border: 3px solid #ec2d38;
        }
    }
}


/*
   *  Accordion-Wizard-Form
   * ----------------------
   */

div {
    &[data-acc-content] {
        display: none;
    }
    &[data-acc-step]:not(.open) {
        background: #f4f5fb;
        h5 {
            color: #8e9cad;
        }
        .bg-primary {
            background: #afb4d8 !important;
        }
    }
}

#form .list-group-item:hover {
    background: none;
}

#smartwizard {
    .nav.nav-tabs.step-anchor {
        border-bottom: 0 !important;
        padding: 30px;
    }
    .nav-tabs {
        border-top: 0 !important;
    }
    .nav-link {
        padding: 15px 40px;
    }
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
    color: $primary;
    background: transparent;
}

.fc-widget-content .fc-bgevent {
    background-color: #f4f5fb !important;
}

.apexcharts-toolbar {
    z-index: 0 !important;
    display: none;
}

.card-bgimg {
    background: url(../images/photos/chart.png);
    background-size: cover;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    border: 0;
    &:before {
        opacity: 0.9;
        border-radius: 3px;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        display: block;
        top: 0;
        background: $primary;
    }
    .card-body {
        z-index: 10;
    }
}


/*Horizontal Centerlogo */

@media (min-width: 992px) {
    .top-header.hor-top-header {
        .header-brand-img {
            display: none;
        }
        .header-brand.center-logo .desktop-lgo {
            left: 0;
            display: block;
            position: absolute;
            margin: 0 auto;
            right: 0px;
        }
        .form-inline .form-control {
            width: 175px;
        }
        .header-brand {
            margin-right: 0rem;
        }
    }
}

@media (max-width: 991px) {
    .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
        display: none;
    }
    .header.top-header.hor-top-header {
        border-bottom: 0px;
    }
}

.icon.icon-shape {
    width: 2.5rem;
    height: 2.2rem;
    line-height: 2.2rem;
    text-align: center;
    font-size: 12px;
}

.h-250 {
    height: 150px;
}

.latest-timeline1 li {
    position: relative;
}

.latest-timeline.latest-timeline1-icon ul.timeline>li:before {
    display: none;
}

.latest-timeline1-icon {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 7px;
    line-height: 2rem;
    text-align: center;
    left: 0;
    z-index: 1;
    color: $white;
    font-weight: 500;
}

.h-330 {
    height: 330px;
}

.shadow {
    box-shadow: 0 2px 16px rgba(176, 184, 214, 0.7) !important;
}

.shadow3 {
    box-shadow: 0 0px 6px rgba(176, 184, 214, 0.7) !important;
}

.apexcharts-tooltip.light {
    border: 1px solid #e3e3e3;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0 0px 17px -5px rgba(68, 84, 195, 0.5);
}

.chart-dropshadow-primary {
    filter: drop-shadow((-6px) 12px 4px rgba(68, 84, 195, 0.2));
}

.icon-dropshadow {
    filter: drop-shadow(0px 10px 4px rgb(229, 233, 242));
}

.icon-dropshadow-primary {
    filter: drop-shadow(0px 4px 4px rgba(68, 84, 195, 0.3));
}

.icon-dropshadow-secondary {
    filter: drop-shadow(0px 4px 4px rgba(247, 45, 102, 0.3));
}

.icon-dropshadow-info {
    filter: drop-shadow(0px 4px 4px rgba(69, 170, 242, 0.3));
}

.icon-dropshadow-success {
    filter: drop-shadow(0px 4px 4px rgba(45, 206, 137, 0.3));
}

.icon-dropshadow-danger {
    filter: drop-shadow(0px 4px 4px rgba(255, 91, 81, 0.3));
}

.icon-dropshadow-warning {
    filter: drop-shadow(0px 4px 4px rgba(236, 180, 3, 0.3));
}

.chart-dropshadow {
    -webkit-filter: drop-shadow(0 -6px 4px rgba(176, 184, 214, 0.5));
    filter: drop-shadow(0 -6px 4px rgba(176, 184, 214, 0.5));
}

.GradientlineShadow {
    -webkit-filter: drop-shadow(0 -8px 4px rgba(176, 184, 214, 0.2));
    filter: drop-shadow(0 -8px 4px rgba(176, 184, 214, 0.2));
}

.BarChartShadow {
    -webkit-filter: drop-shadow(0px 1px 4px rgba(176, 184, 214, 0.3));
    filter: drop-shadow(0px 1px 4px rgba(176, 184, 214, 0.3));
}

.donutShadow {
    -webkit-filter: drop-shadow((-5px) 0px 10px rgba(176, 184, 214, 0.4));
    filter: drop-shadow((-5px) 0px 10px rgba(176, 184, 214, 0.4));
}

.transaction-table.table {
    td {
        vertical-align: middle;
    }
    .apexcharts-tooltip.light {
        display: none;
    }
    thead th {
        padding-bottom: 20px;
    }
}

.inde4-table tr {
    td:first-child {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 2px;
            height: 24px;
            left: 0px;
            top: 12px;
        }
    }
    &:nth-child(1) td:first-child:before {
        background: rgba(68, 84, 195, 0.7);
    }
    &:nth-child(2) td:first-child:before {
        background: rgba(247, 45, 102, 0.7);
    }
    &:nth-child(3) td:first-child:before {
        background: rgba(45, 206, 137, 0.7);
    }
    &:nth-child(4) td:first-child:before {
        background: rgba(69, 170, 242, 0.7);
    }
    &:nth-child(5) td:first-child:before {
        background: rgba(236, 180, 3, 0.7);
    }
    &:nth-child(6) td:first-child:before {
        background: rgba(255, 91, 81, 0.7);
    }
    &:nth-child(7) td:first-child:before {
        background: rgba(68, 84, 195, 0.7);
    }
    &:nth-child(8) td:first-child:before {
        background: rgba(247, 45, 102, 0.7);
    }
    &:nth-child(9) td:first-child:before {
        background: rgba(45, 206, 137, 0.7);
    }
    &:nth-child(10) td:first-child:before {
        background: rgba(69, 170, 242, 0.7);
    }
}

.crypto-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    line-height: 3rem;
    text-align: center;
    font-size: 1.8rem;
    margin-right: 18px;
}

.crypto-tabs {
    padding: 0 !important;
    border: 0 !important;
    .tabs-menu1 ul li {
        .active {
            background: $white;
            border-bottom: 0;
            border-radius: 8px 8px 0 0;
            box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px rgb(176, 184, 214);
            border: 1px solid #eff0f6;
            border-bottom: 0;
        }
        a {
            display: block;
            font-size: 16px;
        }
    }
}

table.table-bordered.dataTable td:first-child {
    border-left: 0px solid #eff0f6 !important;
}

.js-conveyor-example {
    &:before {
        width: 20%;
        position: absolute;
        content: '';
        height: 100%;
        background: linear-gradient(to right, $background, transparent);
        z-index: 1;
        left: 0;
        top: 0px;
    }
    &:after {
        width: 20%;
        position: absolute;
        content: '';
        height: 100%;
        background: linear-gradient(to left, $background, transparent);
        z-index: 1;
        right: 0;
        top: 0px;
    }
}

.user-info .social {
    li {
        display: inline-block;
    }
    .social-icon {
        color: $white;
        width: 2.5rem;
        height: 2.5rem;
        display: block;
        border: 1px solid $white-1;
        background: $white-1;
        line-height: 2.5rem;
        border-radius: 50%;
    }
}

.date-range-btn {
    font-size: 0.9375rem;
}

.side-menu__icon {
    color: #8e98db;
    fill: #d2d6f1;
}

.sidemneu-icon2 {
    fill: #8e98db;
}

.side-menu__item.active .side-menu__icon {
    color: #8e98db;
    fill: #d2d6f1;
}

.svg-icon,
.header-icon,
.header-icon2 {
    color: #8e98db;
    fill: #8e98db;
}

.header-icon {
    width: 30px;
    height: 30px;
}

.header-option {
    .header-icon {
        margin-top: -3px;
    }
    .nav-link {
        margin: 7px 0 0 0;
    }
}

.header-option .dropdown-menu {
    font-size: 13px;
}

.card-custom-icon {
    &.text-secondary {
        fill: $secondary;
    }
    &.text-success {
        fill: $success;
    }
    &.text-primary {
        fill: $primary;
    }
}

.feature .feature-icon {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    border-radius: 50%;
    background: rgba(68, 84, 195, 0.2);
    text-align: center;
    font-size: 25px;
    color: $primary;
}

.expenses-card {
    #Chart {
        width: 105% !important;
        bottom: -15px;
        position: relative;
        left: -7px;
        height: 120px !important;
    }
    .card-body {
        position: absolute;
        top: 0;
    }
    padding-top: 170px;
}

.page-rightheader .breadcrumb {
    padding: 3px 15px 5px 15px;
    background: #fff;
    border-radius: 15px;
}

.under-countdown .countdown {
    background: $primary;
    color: $white;
    height: 100px;
    line-height: 100px;
    border-radius: 5px;
}


/*----- clipboard-----*/

.clip-widget {
    position: relative;
}

.clipboard-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 0px;
    background: #dde0f1;
    color: #caceeb;
    border-radius: 5px;
    width: 35px;
    cursor: pointer;
    /* line-height: 37px; */
    height: 35px;
    margin: 0 auto;
    text-align: center;
    border: 1px solid #caceeb;
    .svg-icon {
        margin-top: 5px;
    }
}

.highlight {
    position: relative;
    background: #f7f6fd;
    padding: 0;
    border-radius: 0 0 3px 3px;
    pre {
        color: inherit;
        margin-bottom: 0;
        max-height: 15rem;
        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;
        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
    }
}


/*----- clipboard-----*/

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]> {
    td:first-child:before,
    th:first-child:before {
        top: 12px;
        left: 4px;
        height: 14px;
        width: 14px;
        display: block;
        position: absolute;
        color: white;
        border: 2px solid #4757c4;
        border-radius: 14px;
        box-shadow: none;
        box-sizing: content-box;
        text-align: center;
        text-indent: 0 !important;
        line-height: 14px;
        content: '+';
        background: #4757c4;
    }
}

.usertab-list li {
    margin: 5px 0;
}

.profile-media-img {
    border: 0px solid #efefef;
    display: block;
    h6 {
        position: absolute;
        bottom: 15px;
        left: 15px;
        color: $white;
    }
    position: relative;
    display: block;
    &:before {
        content: '';
        background: $black-5;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        position: absolute;
    }
    h6 a {
        position: relative;
        z-index: 999;
    }
}

.cover-image {
    background-size: cover !important;
}

.timelineleft {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        background: rgba(152, 166, 173, 0.3);
        left: 31px;
        margin: 0;
        border-radius: 2px;
    }
    >li {
        position: relative;
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.timelinelft>li:before {
    content: " ";
    display: table;
}

.timelineleft> {
    li:after {
        content: " ";
        display: table;
    }
    .timeleft-label>span {
        font-weight: 600;
        padding: 5px 10px;
        display: inline-block;
        background-color: $white;
        color: $white;
        border-radius: 4px;
    }
    li {
        &:after {
            clear: both;
        }
        >.timelineleft-item {
            -webkit-box-shadow: 0 1px 1px $black-1;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            border-radius: 7px;
            margin-top: 0;
            background: $white;
            color: #444;
            margin-left: 60px;
            margin-right: 0;
            padding: 0;
            position: relative;
            border: 1px solid #ebeaf1;
            > {
                .time {
                    color: #999;
                    float: right;
                    padding: 12px;
                    font-size: 12px;
                }
                .timelineleft-header {
                    margin: 0;
                    color: #555;
                    border-bottom: 1px solid #ebeaf1;
                    padding: .75rem 1.25rem;
                    font-size: 16px;
                    line-height: 1.1;
                    &.no-border {
                        border-bottom: 0px solid #ebeaf1;
                    }
                    >a {
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.timelineleft-body img {
    width: 150px;
    height: 100px;
}

.timelineleft>li>.timelineleft-item> {
    .timelineleft-body,
    .timelineleft-footer {
        padding: .75rem 1.25rem;
    }
}

.timelineleft-body {
    font-size: 14px;
}

.timelineleft>li> {
    .fa,
    .glyphicon,
    .ion {
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        position: absolute;
        color: $white;
        background: #d2d6de;
        border-radius: 50%;
        text-align: center;
        left: 18px;
        top: 0;
    }
}

.acc-step-number {
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
}

#form .list-group-item {
    padding: 25px;
}

.form-switch-indicator-lg {
    display: inline-block;
    height: 1.5rem;
    width: 3rem;
    background: #f5f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #ebeaf1;
    transition: .3s border-color, .3s background-color;
    &:before {
        content: '';
        position: absolute;
        height: calc(1.5rem - 4px);
        width: calc(1.5rem - 4px);
        top: 1px;
        left: 1px;
        background: $white;
        border-radius: 50%;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 $black-4;
    }
}

.form-switch-input:checked~.form-switch-indicator-lg:before {
    left: calc(1.46rem + 1px);
}

.form-switch-indicator-xl {
    display: inline-block;
    height: 2rem;
    width: 4rem;
    background: #f5f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #ebeaf1;
    transition: .3s border-color, .3s background-color;
    &:before {
        content: '';
        position: absolute;
        height: calc(2rem - 4px);
        width: calc(2rem - 4px);
        top: 1px;
        left: 1px;
        background: $white;
        border-radius: 50%;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 $black-4;
    }
}

.form-switch-input:checked~.form-switch-indicator-xl:before {
    left: calc(1.96rem + 1px) !important;
}

.header-icon2 {
    width: 25px;
    position: absolute;
    left: 10px;
    top: 0px;
}

.page-rightheader .date-range-btn {
    position: relative;
    padding-left: 40px;
    outline: none;
    height: 40px;
    line-height: 28px;
    border-radius: 5px;
    .header-icon2 {
        width: 20px;
        position: absolute;
        left: 10px;
        top: 0px;
        color: $primary;
        fill: #8e98db;
    }
}

.main-proifle {
    position: relative;
    background: #FFF;
    padding: 25px;
    padding-bottom: 0;
    margin-bottom: 30px;
    border-top: 1px solid #eff0f6;
    border-radius: 8px;
    overflow: hidden;
    .tab-menu-heading {
        border-left: 0;
        border-right: 0;
        margin: 0 -30px;
        margin-top: 25px;
    }
}

.profile-edit {
    position: relative;
}

.profile-share {
    width: 100%;
    padding: 10px;
    background: #f3f4f7;
    border-radius: 0 0 5px 5px;
    display: inline-block;
}

.profile-edit textarea {
    background: #f3f4f7;
    border-radius: 5px 5px 0 0;
    &:focus {
        background: #f3f4f7;
        border: 1px solid #eff0f6;
    }
}

.social-profile-buttons li {
    float: left;
    display: block;
    a {
        display: block;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        border-radius: 50%;
        background: #d2d6f1;
        border: 2px solid #8e98db;
        margin: 2px;
        i {
            color: $primary;
        }
    }
}

.fill-secondary {
    fill: $secondary;
}

.fill-white {
    fill: transparent;
}

.main-nav-calendar-event .nav-link {
    padding: 0;
    height: 30px;
    line-height: 30px;
    &.exclude {
        position: relative;
        opacity: 0.3;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            border-top: 1px solid #737688;
        }
    }
}

.form-wizard-title {
    line-height: 25px;
    margin-left: 10px;
}

.footer .social .social-icon {
    width: 25px;
    height: 25px;
    display: block;
    border: 1px solid #e4e9f1;
    line-height: 25px;
    border-radius: 50%;
    background: #e4e9f1;
    color: $primary;
}

.modal-open .select2-dropdown {
    z-index: 10000 !important;
}

.daterangepicker.opensright:before {
    display: none;
}

.daterangepicker.opensright:after {
    display: none;
}

@media (max-width:1400px) {
    .bg-primary.card.text-white .d-block.card-header h2 {
        font-size: 20px;
    }
}

.crypto-content .select2-container--default .select2-selection--single {
    border-radius: 0px 5px 5px 0 !important;
}

.page-style1 .page,
.page-style1 .page-content {
    min-height: 100% !important;
}

.invoice-table-responsive {
    overflow: inherit !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #e8ebf2;
    border-bottom: 1px solid $border;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid $border;
    background: $white;
}

.apexcharts-text .apexcharts-xaxis text {
    fill: $color;
}

.date-range-btn i {
    color: #c4c9d6;
    opacity: 0.5;
}

.app-sidebar .dropdown-menu-arrow.dropdown-menu-end:before {
    bottom: -10px;
    top: auto;
    transform: rotate(-180deg);
    right: auto;
    left: 12px;
}

.app-sidebar .dropdown-menu-arrow.dropdown-menu-end:after {
    bottom: -9px;
    top: auto;
    transform: rotate(-180deg);
    right: auto;
    left: 13px;
}

.legend div {
    background-color: transparent !important;
}

.crypto-content .form-control {
    height: 38px;
}

table.table-bordered.dataTable td:first-child:focus {
    outline: 0;
    box-shadow: none;
}

@media (min-width: 991px) {
    .top-header1 .header-brand2 .header-brand-img {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 18px;
    }
    .top-header1 .header-brand {
        display: none;
    }
    .top-header1 .form-inline .form-control {
        width: 210px;
    }
}

.header-notify.show .dropdown-menu {
    left: -180px !important;
}

.profile-dropdown.show .dropdown-menu {
    left: -140px !important;
}

.countryscroll .ps__thumb-y {
    right: 12px !important;
}


/*---- IE11 Support ----*/

*::-ms-backdrop,
.img-height {
    height: 100%;
}

*::-ms-backdrop,
.img-height1 {
    height: 333px;
}

*::-ms-backdrop,
.social-profile-buttons li {
    float: none;
    display: inline-block;
}

*::-ms-backdrop,
.social-profile-buttons ul {
    margin-bottom: 0;
}

*::-ms-backdrop,
.btn-icon.btn-svg .svg-icon {
    height: 16px;
}

*::-ms-backdrop,
.price-svg .price-title {
    top: 0 !important;
}

*::-ms-backdrop,
.price-svg svg {
    height: 65px !important;
}

*::-ms-backdrop,
.item7-card-img {
    height: 100%;
}

*::-ms-backdrop,
.main-content {
    height: 100%;
}

*::-ms-backdrop,
.page-single,
.page-single-content {
    display: inline-block !important;
}

*::-ms-backdrop,
.page,
.page-content {
    display: -ms-flexbox !important;
}

*::-ms-backdrop,
.h-100vh .page {
    height: 100% !important;
}

*::-ms-backdrop,
.page-style1 {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

*::-ms-backdrop,
.app-sidebar-help {
    display: none;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: transparent;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
    border-bottom-width: 0 !important;
}

.table>:not(caption)>*>* {
    border-bottom-width: 0px
}

.default-sidebar.sidenav-toggled .app-sidebar2 .mobile-logo {
    display: block;
    margin: 0 auto;
    margin-top: 5px;
}

.default-sidebar .app-sidebar-help .header-icon {
    color: #8e98db;
    fill: #d2d6f1;
}

@media only screen and (max-width: 567px) {
    .app.light-mode .app-header .header-brand .header-brand-img.mobile-logo {
        display: block !important;
        margin-top: 8px;
    }
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #eff0f6;
}

.default-sidebar .app-sidebar-help {
    border-top: 1px solid #eff0f6;
    background: #fff;
}

.table {
    --bs-table-hover-bg: none;
}

.nav-link {
    color: #424e79;
}

.fc-list-heading {
    border-bottom-width: 0 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>") no-repeat center right 0.5rem/1rem;
}

.table-bordered>:not(caption)>* {
    border-width: 0;
}

.table-primary {
    --bs-table-bg: none;
}

.table-warning {
    --bs-table-bg: none;
}

.btn-close {
    float: right;
    font-size: 1.40625rem;
    font-weight: 700;
    line-height: 1;
    color: #495057;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    transition: .3s color;
    background: none;
    width: 0;
    height: 0;
    margin-top: -7px;
    margin-left: 3px;
    outline: none;
}

hr {
    background-color: transparent;
    opacity: 1;
}

.carousel-indicators [data-bs-target] {
    width: 7px;
    height: 7px;
}

.modal-header .btn-close {
    margin: -15px 2px;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    background-color: #4757c4 !important;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: none !important;
    color: #fff !important;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: none !important;
    color: #fff !important;
    background-color: #2dce89 !important;
    border: #2dce89 !important;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: none !important;
    color: #fff !important;
    background-color: #4757c4 !important;
    border: #4757c4 !important;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: none !important;
    color: #fff !important;
    background-color: #45aaf2 !important;
    border: #45aaf2 !important;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    color: #fff !important;
    background-color: #dc0441 !important;
    border-color: #dc0441 !important;
    box-shadow: none !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    box-shadow: none !important;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    color: #4757c4;
}

.input-group>.btn-group>.btn,
.input-group>.btn-group>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    color: inherit !important;
}

@media (max-width: 576px) {
    .active .header.top-header {
        z-index: 9999;
    }
}

.color-sidebar .side-menu-label1 {
    display: none;
}

.color-sidebar {
    .side-menu__item:hover {
        .side-menu__label,
        .side-menu__icon,
        .angle {
            color: $white !important;
        }
    }
}

table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable td:first-child {
    border-left-width: 0px !important;
}

.select2.select2-container.select2-container--default {
    width: 100% !important;
}